import { useRef, useEffect } from "react";
import {
  setIntersectionObserver,
  unsetIntersectionObserver,
} from "../utils/setIntersectionObserver";

const useFiftyPercentInView = (callback) => {
  const observer = useRef(null);
  const element = useRef(null);

  const refCallback = (node) => {
    if (node && !observer.current) {
      element.current = node;
      observer.current = setIntersectionObserver({
        element: element.current,
        options: {
          threshold: 0.5,
        },
        handleIntersectionData: ({ isIntersecting }) => {
          if (isIntersecting) callback();
        },
        observeIntersectionOnce: true,
      });
    }
  };

  useEffect(
    () => () => {
      unsetIntersectionObserver(element.current, observer.current);
    },
    []
  );

  return refCallback;
};

export default useFiftyPercentInView;
