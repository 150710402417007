import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { getPrimaryVariant } from "../../utils/product";
import saveForLater from "../../utils/saveForLater";
import { useProduct } from "../../context/product/ProductContext";
import { useVariant } from "../../context/variant/VariantContext";
import { useLooksProducts } from "../../context/looks/LooksProductsContext";
import {
  PAGE_AREAS,
  LOOKS_RECOMMENDATIONS_HEADER,
  SMALL_VIEWPORT,
} from "../../constants";
import { fireLooksAddToBagAnalytics } from "../../analytics/addToBag";
import fireNotifyMeClickAnalytics from "../../analytics/backInStock";
import ProductCard from "../productCard/ProductCard";
import styles from "./LooksProduct.scss";
import { useViewport } from "../../context/viewport/ViewportContext";

const productLink = ({ looksProducts, product, isMobile }) => {
  const indexInCarousel = looksProducts.findIndex(
    ({ id }) => id === product.id
  );
  const btlType = isMobile ? "pop+up" : "content";

  return `${product.pdpUrl}#ctaRef-btl+${btlType}+-+btl+carousel_${
    indexInCarousel + 1
  }&featureref1-btl+${btlType}+btl+carousel`;
};

export default function LooksProduct(props) {
  const { product } = useProduct();
  const { selectedVariant } = useVariant();
  const { looksProducts } = useLooksProducts();
  const { viewportCategory } = useViewport();
  const { isMobile } = useRuntimeConstants();

  if (!product) {
    return null;
  }

  const defaultColourWayId = getPrimaryVariant(product.variants).colourWayId;
  const variantIdentifier = selectedVariant
    ? `variantId:${selectedVariant.variantId}`
    : `colourWayId:${defaultColourWayId}`;

  const handleSaveClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const identifier = selectedVariant
      ? { variantId: selectedVariant.variantId }
      : { colourWayId: defaultColourWayId };

    saveForLater({
      product,
      items: [{ productId: product.id, ...identifier }],
      pageArea: PAGE_AREAS.LOOKS,
      looksProducts,
    });
  };

  const enhanceNotifyMeClickAnalytics = (selectedVariantId) => {
    fireNotifyMeClickAnalytics(
      selectedVariantId,
      PAGE_AREAS.LOOKS,
      looksProducts
    );
  };

  return (
    <div className={styles.container}>
      <ProductCard
        {...{
          product,
          handleSaveClick,
          productLink: productLink({ looksProducts, product, isMobile }),
          variantIdentifier,
          variantSelectorId: "looksVariantSelector",
          scope: LOOKS_RECOMMENDATIONS_HEADER,
          fireAddToBagAnalytics: fireLooksAddToBagAnalytics(looksProducts),
          fireNotifyMeClickAnalytics: enhanceNotifyMeClickAnalytics,
          imageWidth: viewportCategory === SMALL_VIEWPORT ? 163 : 218,
          ...props,
        }}
      />
    </div>
  );
}
