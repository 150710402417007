import { useLayoutEffect } from "react";
import PropTypes from "prop-types";
import Image from "../../image/Image";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import styles from "./OutOfStockHeader.scss";
import classnamesBind from "classnames/bind";

const classnames = classnamesBind.bind(styles);

export default function OutOfStockHeader({
  productImageUrl,
  productImageAlt,
  title,
  message,
}) {
  const { isMobile } = useRuntimeConstants();

  useLayoutEffect(() => {
    const loader = document.getElementById("oos-hero-skeleton-header");

    if (loader) {
      loader.parentElement.removeChild(loader);
    }
  }, []);

  return (
    <header className={styles.header}>
      <Image
        additionalClasses={classnames(styles.image, {
          [styles.smallViewport]: isMobile,
        })}
        url={productImageUrl}
        alternateText={productImageAlt}
        type={"hero"}
        width={100}
        loadingType={"lazy"}
      />
      <div>
        <h2
          className={classnames(styles.title, {
            [styles.smallViewport]: isMobile,
          })}
        >
          {title}
        </h2>
        <p
          className={classnames(styles.message, {
            [styles.smallViewport]: isMobile,
          })}
        >
          {message}
        </p>
      </div>
    </header>
  );
}

OutOfStockHeader.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  productImageUrl: PropTypes.string.isRequired,
  productImageAlt: PropTypes.string.isRequired,
};
