import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./RatingsAndReviewsModal.scss";
import Modal from "../modal/Modal";
import { StarRatingGroup } from "../ratingComponents";
import { RATINGS_LARGE } from "../ratingComponents/ratingTypes";
import Recommended from "../recommended/Recommended";
import RatingsDistribution from "../ratingsDistribution/RatingsDistribution";
import SubRatings from "../subRatings/SubRatings";
import Reviews from "../reviews/Reviews";
import ModalOverlay from "../modalOverlay/ModalOverlay";
import ReviewPhotoGallery from "../reviewPhotoGallery/ReviewPhotoGallery";
import Pill from "../buttons/pill/Pill";
import Toast from "../toast/Toast";
import { FILTER_ERROR, VIEW_MORE_ERROR, MODAL_TYPE } from "../../constants";
import classnamesBind from "classnames/bind";
import scrollIntoView from "../../utils/scrollIntoView/scrollIntoView";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { MODAL_OVERLAY_TYPES } from "../modalOverlay/modalOverlayTypes";

const classnames = classnamesBind.bind(styles);
const FILTER_PILL_ID = "filterPill";

export default function RatingsAndReviewsModal({
  getReviewsCallback,
  filterParam,
  filterValue,
  removeFilter,
  screenHoverable,
  open,
  closeRatingsModal,
  ratingsModalFocusOnClose,
  filterPending,
  ratings,
  language,
  ratingsApiError,
  dismissApiError,
  ratingsApiErrorTranslation,
  viewMorePending,
  filteredReviewCount,
}) {
  const { getTranslation } = useRuntimeConstants();
  const timeoutRef = useRef();
  const [gallery, setGallery] = useState({});
  const [modalId] = useState(
    `${MODAL_TYPE.SIDE}${Math.floor(Math.random() * 10000)}`
  );

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  const togglePhotoGallery = (
    isOpen,
    reviewPhotoList,
    atIndex,
    targetElement
  ) => {
    if (!isOpen && gallery.targetElement) {
      let focusTarget = gallery.targetElement;

      if (focusTarget.tagName.toLowerCase() === "img") {
        focusTarget = focusTarget.parentElement;
      }

      focusTarget && focusTarget.focus();
    }

    setGallery({
      isOpen,
      reviewPhotoList,
      atIndex,
      targetElement,
    });
  };

  const renderFilterPill = () => {
    if (filterParam)
      return (
        <div id={FILTER_PILL_ID} className={styles.ratingsPillContainer}>
          <Pill
            iconClass={`${styles.closeIcon} product-closesmall`}
            ariaLabel={getTranslation(
              "rdp_ratings_reviews_accessibility_filter_pill_description",
              {
                ratingsValue: filterValue,
              }
            )}
            label={getTranslation("rdp_ratings_reviews_distribution_label", {
              smart_count: filterValue,
              ratingsValue: filterValue,
            })}
            handleClick={removeFilter}
          />
        </div>
      );
  };

  const renderPhotoGallery = () => {
    if (gallery.isOpen) {
      return (
        <ModalOverlay modalId={modalId} type={MODAL_OVERLAY_TYPES.dark}>
          <ReviewPhotoGallery
            gallery={gallery}
            togglePhotoGallery={togglePhotoGallery}
            modalId={modalId}
            screenHoverable={screenHoverable}
          />
        </ModalOverlay>
      );
    }
  };

  const renderToast = () => {
    if (ratingsApiError) {
      return (
        <Toast
          close={dismissApiError}
          ariaLabelledBy="ratingsApiErrorDescription"
        >
          <p
            className={classnames(styles.apiErrorMessage, {
              apiFilterErrorMessage: ratingsApiError === FILTER_ERROR,
            })}
            id="ratingsApiErrorDescription"
          >
            {getTranslation(ratingsApiErrorTranslation)}
          </p>
          {ratingsApiError === VIEW_MORE_ERROR && (
            <button
              className={styles.apiErrorButton}
              onClick={getReviewsCallback}
            >
              {getTranslation("plp_web_errors_retry")}
            </button>
          )}
        </Toast>
      );
    }
  };

  const handleOverlayClick = () => {
    timeoutRef.current && clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      togglePhotoGallery(false);
    }, 500);
  };

  const filterByRatingCallback = (ratingsValue) => async () => {
    await getReviewsCallback(ratingsValue);
    scrollIntoView(FILTER_PILL_ID, "start");
  };

  return (
    <>
      <Modal
        title={getTranslation("pdp_ratings_reviews_reviews_title")}
        open={open}
        contentLoaded={!filterPending}
        closeModal={closeRatingsModal}
        focusOnClose={ratingsModalFocusOnClose}
        modalId={modalId}
        overlayOpen={gallery.isOpen}
        handleOverlayClick={handleOverlayClick}
        modalType={MODAL_TYPE.SIDE}
      >
        <div
          id="ratingsAndReviewsModal"
          className={styles.ratingsAndReviewsModal}
          data-testid="ratings-and-reviews-modal-content"
        >
          <StarRatingGroup
            averageOverallStarRating={ratings.averageOverallStarRating}
            averageOverallRating={ratings.averageOverallRating}
            totalReviewCount={ratings.totalReviewCount}
            language={language}
            type={RATINGS_LARGE}
          />
          <Recommended
            percentageRecommended={ratings.percentageRecommended}
            language={language}
            additionalClasses={styles.recommended}
          />
          <RatingsDistribution
            ratingDistribution={ratings.ratingDistribution}
            totalReviewCount={ratings.totalReviewCount}
            filterParam={filterParam}
            language={language}
            filterByRatingCallback={filterByRatingCallback}
          />
          <SubRatings
            subRatings={ratings.subRatings}
            additionalClasses={styles.subRatings}
          />
          {renderFilterPill()}
          <div
            className={classnames(styles.reviews, {
              reviewsUnFiltered: !filterParam,
            })}
          >
            <Reviews
              reviews={ratings.reviews}
              totalReviewCount={filteredReviewCount || ratings.totalReviewCount}
              getReviewsCallback={getReviewsCallback}
              viewMorePending={viewMorePending}
              ratingsApiError={ratingsApiError}
              language={language}
              togglePhotoGallery={togglePhotoGallery}
            />
          </div>
        </div>
        {renderPhotoGallery()}
        {renderToast()}
      </Modal>
    </>
  );
}

RatingsAndReviewsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  ratingsModalFocusOnClose: PropTypes.node,
  closeRatingsModal: PropTypes.func.isRequired,
  ratings: PropTypes.shape({
    averageOverallStarRating: PropTypes.number.isRequired,
    averageOverallRating: PropTypes.number.isRequired,
    totalReviewCount: PropTypes.number.isRequired,
    percentageRecommended: PropTypes.number.isRequired,
    subRatings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        minLabel: PropTypes.string.isRequired,
        midLabel: PropTypes.string,
        maxLabel: PropTypes.string.isRequired,
        percentage: PropTypes.number.isRequired,
      }).isRequired
    ).isRequired,
    ratingDistribution: PropTypes.arrayOf(
      PropTypes.shape({
        ratingsValue: PropTypes.number.isRequired,
        count: PropTypes.number.isRequired,
      }).isRequired
    ).isRequired,
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        reviewText: PropTypes.string,
        rating: PropTypes.number.isRequired,
        submissionRecency: PropTypes.string.isRequired,
        syndicationSource: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
        badgesOrder: PropTypes.array.isRequired,
      }).isRequired
    ),
  }),
  language: PropTypes.string.isRequired,
  getReviewsCallback: PropTypes.func.isRequired,
  viewMorePending: PropTypes.bool.isRequired,
  filterPending: PropTypes.bool.isRequired,
  ratingsApiError: PropTypes.oneOf([FILTER_ERROR, VIEW_MORE_ERROR, false])
    .isRequired,
  ratingsApiErrorTranslation: PropTypes.string,
  dismissApiError: PropTypes.func.isRequired,
  filteredReviewCount: PropTypes.number,
  filterParam: PropTypes.string,
  filterValue: PropTypes.number,
  removeFilter: PropTypes.func.isRequired,
  screenHoverable: PropTypes.bool,
};
