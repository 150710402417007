import PropTypes from "prop-types";
import styles from "./MightLike.scss";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import classnamesBind from "classnames/bind";
import RankingDisclaimerMessage from "../rankingDisclaimerMessage/RankingDisclaimerMessage";

const classnames = classnamesBind.bind(styles);

export const RECOMMENDATION_TYPES = {
  CAROUSEL: "carousel",
  GRID: "grid",
  GRID_LOOKS_MOBILE: "grid-looks-mobile",
  GRID_LOOKS_DESKTOP: "grid-looks-desktop",
};

export default function MightLike({
  title,
  titleAriaLabel,
  id = "mightLike",
  itemCount,
  children,
  trackingRef,
  type = RECOMMENDATION_TYPES.CAROUSEL,
}) {
  const { getTranslation } = useRuntimeConstants();
  const shouldRenderItemCount = itemCount > 0;

  return (
    <div
      id={id}
      className={classnames(
        styles.fullwidth_container,
        styles[`section-${type}`]
      )}
    >
      <section
        ref={trackingRef}
        className={classnames(styles.container, styles[`container-${type}`])}
        id="mightLikeContainer"
        aria-label={titleAriaLabel}
      >
        <header
          className={classnames(styles.header, styles[`header-${type}`], {
            hasItemCount: shouldRenderItemCount,
          })}
        >
          {type != RECOMMENDATION_TYPES.GRID_LOOKS_DESKTOP && (
            <h2 id="mightLikeTitle" className={styles.title}>
              {title}
            </h2>
          )}
          {shouldRenderItemCount && (
            <p className={styles.itemCount}>
              {getTranslation("buy_the_look_items_count", {
                smart_count: itemCount,
                count: itemCount,
              })}
            </p>
          )}
          <RankingDisclaimerMessage />
        </header>
        {children}
      </section>
    </div>
  );
}

MightLike.propTypes = {
  title: PropTypes.string,
  titleAriaLabel: PropTypes.string,
  id: PropTypes.string,
  itemCount: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.node),
  trackingRef: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(RECOMMENDATION_TYPES)),
};
