import PropTypes from "prop-types";
import styles from "./Pill.scss";
import classnamesBind from "classnames/bind";

const classnames = classnamesBind.bind(styles);
export default function Pill({
  iconClass,
  ariaLabel,
  additionalClasses,
  testId,
  label,
  handleClick,
}) {
  return (
    <button
      id="pill"
      className={styles.pillButton}
      aria-label={ariaLabel}
      data-testid={testId}
      onClick={handleClick}
    >
      <div className={classnames(styles.pill, additionalClasses)}>
        <span className={styles.content}>{label}</span>
        <span className={iconClass} />
      </div>
    </button>
  );
}

Pill.propTypes = {
  iconClass: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  additionalClasses: PropTypes.string,
  testId: PropTypes.string,
};
