import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useEffect, useState } from "react";
import { formatUrl } from "../../utils/url";
import { getPrimaryImage } from "../../utils/product";
import { useViewport } from "../../context/viewport/ViewportContext";
import {
  LARGE_VIEWPORT,
  MEDIUM_VIEWPORT,
  SMALL_VIEWPORT,
} from "../../constants";

const encodeShareParams = (params) => {
  const encodedItems = [];
  const paramKeys = Object.keys(params);
  for (let i = 0; i < paramKeys.length; i++) {
    const key = paramKeys[i];
    encodedItems.push(`${key}=${encodeURIComponent(params[key])}`);
  }
  return encodedItems.join("&");
};

const buildPlatforms = ({ name, canonicalUrl, productImageUrl }) => [
  {
    storeId: "RU",
    icon: "product-vk-icon",
    platform: "vk",
    link: `http://vk.com/share.php?${encodeShareParams({
      url: `${canonicalUrl}#ctaref-product_share_vk`,
    })}`,
    viewports: [SMALL_VIEWPORT, MEDIUM_VIEWPORT, LARGE_VIEWPORT],
  },
  {
    icon: "product-facebook-icon",
    platform: "facebook",
    link: `https://www.facebook.com/sharer/sharer.php?${encodeShareParams({
      u: `${canonicalUrl}#ctaref-product_share_facebook`,
    })}`,
    viewports: [SMALL_VIEWPORT, MEDIUM_VIEWPORT, LARGE_VIEWPORT],
  },
  {
    icon: "product-pinterest-icon",
    platform: "pinterest",
    link: `https://pinterest.com/pin/create/button/?${encodeShareParams({
      url: `${canonicalUrl}#ctaref-product_share_pinterest`,
      media: productImageUrl,
      description: name,
    })}`,
    viewports: [SMALL_VIEWPORT, MEDIUM_VIEWPORT, LARGE_VIEWPORT],
  },
  {
    icon: "product-whatsapp-icon",
    platform: "whatsapp",
    link: `https://wa.me/?${encodeShareParams({
      text: `${name} ${canonicalUrl}#ctaref-product_share_whatsapp`,
    })}`,
    viewports: [SMALL_VIEWPORT],
  },
  {
    icon: "product-email-icon",
    platform: "email",
    link: `mailto:?${encodeShareParams({
      subject: name,
      body: `${name} ${canonicalUrl}#ctaref-product_share_email`,
    })}`,
    viewports: [SMALL_VIEWPORT],
  },
];

export default function useShareLinks() {
  const [sharePlatforms, setSharePlatforms] = useState([]);
  const {
    product: { images, name },
    webContext: { storeId },
    canonicalUrl,
  } = useRuntimeConstants();
  const { viewportCategory } = useViewport();

  useEffect(() => {
    const visiblePlatforms = buildPlatforms({
      name,
      canonicalUrl,
      productImageUrl: formatUrl(getPrimaryImage(images).url),
    })
      .filter(
        (shareIcon) =>
          (!shareIcon.storeId || shareIcon.storeId === storeId) &&
          shareIcon.viewports.includes(viewportCategory)
      )
      .map(({ platform, link, icon }) => ({ platform, link, icon }));

    setSharePlatforms(visiblePlatforms);
  }, [canonicalUrl, name, images, storeId, viewportCategory]);

  return sharePlatforms;
}
