import { useEffect, useRef } from "react";
import styles from "./SocialShareLinks.scss";
import useTrapFocus from "../../hooks/useTrapFocus";
import useShareLinks from "./useShareLinks";
import { fireSocialShareIntent } from "../../analytics/pageInteraction";
import classnamesBind from "classnames/bind";

const classnames = classnamesBind.bind(styles);

export default function SocialShareLinks() {
  const firstElement = useRef();
  const focusTrap = useTrapFocus();

  useEffect(() => {
    firstElement.current?.focus();
  }, [firstElement]);

  const visibleShareLinks = useShareLinks();

  return (
    <ul
      id="share-bar"
      aria-labelledby="social-share-button"
      className={styles.socialShareBar}
      ref={focusTrap}
    >
      {visibleShareLinks.map(({ platform, icon, link }, key) => (
        <li key={platform} className={styles.listItem}>
          <a
            href={link}
            onClick={() => fireSocialShareIntent(platform)}
            data-id={platform}
            aria-label={platform}
            ref={key === 0 ? firstElement : undefined}
            className={styles.link}
          >
            <span className={classnames(styles.icon, icon)} />
          </a>
        </li>
      ))}
    </ul>
  );
}
