import makeRequest from "./makeRequest";
import { reviewMapper } from "../../mappers/review";
import getRuntimeConstants from "../runtimeConstants";

export default async function getReviews({ offset, filter } = {}) {
  const {
    webContext: { browseLanguage, storeId },
    product: { id },
    ratingsApiPath,
    ratingsApiTimeout,
  } = getRuntimeConstants();

  const url = `${ratingsApiPath}/${id}`;
  const params = {
    offset,
    filter,
    limit: 10,
    include: "Products",
    store: storeId,
    lang: browseLanguage,
    filteredStats: "reviews",
    sort: "SubmissionTime:desc",
  };

  const mapData = ({ results, totalResults }) => {
    return {
      results: results.map((result) => reviewMapper(result)),
      totalResults,
    };
  };

  const handleErrorReturnValue = (error) => {
    throw error;
  };

  return makeRequest({
    url,
    params,
    timeout: ratingsApiTimeout,
    requestType: "RATINGS_API",
    apiName: "Ratings",
    caller: "Ratings",
    mapData,
    handleErrorReturnValue,
  });
}
