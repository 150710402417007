const COUNT_FORMATS = [
  {
    letter: "",
    limit: 1e3,
    roundValue: 1,
  },
  {
    letter: "K",
    limit: 1e6,
    roundValue: 1000,
  },
  {
    letter: "M",
    limit: Infinity,
    roundValue: 1000000,
  },
];

export const getShortScale = (value, storeCode = "COM") =>
  storeCode === "DK"
    ? ""
    : COUNT_FORMATS.find((format) => value < format.limit)?.letter;

export const getShortValue = (value, storeCode = "COM") => {
  const format = COUNT_FORMATS.find((format) => value < format.limit);

  if (storeCode === "DK")
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return Math.round(value / format.roundValue);
};
