import { useState } from "react";
import Modal from "../../modal/Modal";
import { v4 } from "uuid";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import { resetLooksTrackedProducts } from "../../../analytics/looks/looksProduct";
import { MODAL_TYPE } from "../../../constants";
import { SEE_SIMILAR_OPEN_MODAL_EVENT } from "../constants";
import { fireSeeSimilarModalOpen } from "../../../analytics/looks/seeSimilarModal";
import { useLooksProducts } from "../../../context/looks/LooksProductsContext";
import useEventBus from "../../../hooks/useEventBus";
import LooksSimilarItems from "../looksSimilarItems";
import LooksProduct from "../LooksProduct";
import { VariantContextProvider } from "../../../context/variant/VariantContext";
import { ProductContextProvider } from "../../../context/product/ProductContext";
import { useSelectedLooksProduct } from "../../../context/looks/SelectedLooksProductContext";

export default function SeeSimilarItemsModal() {
  const [open, setOpen] = useState(false);
  const [modalId] = useState(`${MODAL_TYPE.SIDE}-${v4()}`);
  const [focusOnCloseElement, setFocusOnCloseElement] = useState(null);
  const { getTranslation } = useRuntimeConstants();
  const { looksProducts } = useLooksProducts();
  const { selectedLooksProduct } = useSelectedLooksProduct();

  useEventBus(
    SEE_SIMILAR_OPEN_MODAL_EVENT,
    ({ focusOnCloseElement, position }) => {
      setOpen(true);
      setFocusOnCloseElement(focusOnCloseElement);
      fireSeeSimilarModalOpen({ position, products: looksProducts });
      resetLooksTrackedProducts();
    },
    [looksProducts]
  );

  return (
    <Modal
      title={getTranslation("pdp_carousel_recommendations_title_similar_items")}
      modalId={modalId}
      modalType={MODAL_TYPE.SIDE}
      closeModal={() => setOpen(false)}
      contentLoaded={true}
      open={open}
      focusOnClose={focusOnCloseElement}
    >
      {looksProducts?.map((looksProduct) =>
        looksProduct.id === selectedLooksProduct?.id ? (
          <ProductContextProvider
            products={looksProducts}
            productId={looksProduct.id}
            key={looksProduct.id}
          >
            <VariantContextProvider>
              <LooksProduct
                shouldShowProductControls={false}
                shouldRetainSmallImageSize={true}
              />
            </VariantContextProvider>
          </ProductContextProvider>
        ) : null
      )}
      {open && <LooksSimilarItems />}
    </Modal>
  );
}
