import ProductColour from "./ProductColour";
import { useProduct } from "../../context/product/ProductContext";
import { getColours } from "../../../utils/variantUtils";
import { TEXT_SIZES } from "../../constants";

export default function ProductColourForProductCard() {
  const { product } = useProduct();
  const colourList = getColours(product?.variants);

  if (colourList?.length !== 1) {
    return null;
  }

  return (
    <ProductColour colourLabel={colourList[0]} size={TEXT_SIZES.leavesden3} />
  );
}
