import PropTypes from "prop-types";
import styles from "./CircleButton.scss";

export default function CircleButton({
  iconClass,
  handleClick,
  ariaLabel,
  additionalClasses,
  testId,
}) {
  const classes = additionalClasses
    ? `${styles.button} ${additionalClasses}`
    : styles.button;

  return (
    <button
      className={classes}
      onClick={handleClick}
      aria-label={ariaLabel}
      data-testid={testId}
    >
      <span className={iconClass} />
    </button>
  );
}

CircleButton.propTypes = {
  iconClass: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  additionalClasses: PropTypes.string,
  testId: PropTypes.string,
};
