import PropTypes from "prop-types";
import getConfig from "../../config";

const { symbolsSpriteUrl } = getConfig();

const Icon = ({ id, className }) => {
  return (
    <svg className={className}>
      <use href={`${symbolsSpriteUrl}#${id}`} />
    </svg>
  );
};

Icon.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Icon;
