import ReactDOM from "react-dom";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import useElementWhenReady from "../../hooks/useElementWhenReady";
import { StarRatingGroup } from "../../components/ratingComponents";
import { RATINGS_WITHOUT_REVIEWS_TEXT } from "../../components/ratingComponents/ratingTypes";
import useRatingsFeatureRequirements from "../../hooks/ratingsFeatureRequirements/useRatingsFeatureRequirements";
import { useStockPrice } from "../../context/stockPrice/StockPriceContext";
import PropTypes from "prop-types";

const MixAndMatchRatingsContainerItem = ({
  portalId,
  ratings,
  language,
  isInStock,
}) => {
  const ratingsFeatureRequirementsAchieved = useRatingsFeatureRequirements(
    ratings,
    isInStock
  );
  const portalElement = useElementWhenReady(
    `#overall-rating-portal-${portalId}`
  );

  if (!ratingsFeatureRequirementsAchieved) {
    return null;
  }

  return (
    portalElement &&
    ReactDOM.createPortal(
      <StarRatingGroup
        averageOverallStarRating={ratings.averageOverallStarRating}
        averageOverallRating={ratings.averageOverallRating}
        totalReviewCount={ratings.totalReviewCount}
        language={language}
        disabled={true}
        type={RATINGS_WITHOUT_REVIEWS_TEXT}
      />,
      portalElement
    )
  );
};

const MixAndMatchRatingsContainer = () => {
  const { isMixAndMatch, webContext } = useRuntimeConstants();
  const { products } = useStockPrice();

  if (!isMixAndMatch || !products?.products) {
    return null;
  }

  return products.products.map((product) => (
    <MixAndMatchRatingsContainerItem
      key={product.id}
      portalId={product.id}
      isInStock={product.isInStock}
      ratings={product.ratings}
      language={webContext.browseLanguage}
    />
  ));
};

MixAndMatchRatingsContainerItem.propTypes = {
  portalId: PropTypes.string.isRequired,
  ratings: PropTypes.shape({
    averageOverallStarRating: PropTypes.number.isRequired,
    averageOverallRating: PropTypes.number.isRequired,
    totalReviewCount: PropTypes.number.isRequired,
    percentageRecommended: PropTypes.number.isRequired,
    subRatings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        minLabel: PropTypes.string.isRequired,
        midLabel: PropTypes.string,
        maxLabel: PropTypes.string.isRequired,
        percentage: PropTypes.number.isRequired,
      }).isRequired
    ).isRequired,
    mostRecent: PropTypes.shape({
      title: PropTypes.string,
      reviewText: PropTypes.string,
      rating: PropTypes.number.isRequired,
      submissionRecency: PropTypes.string.isRequired,
      syndicationSource: PropTypes.shape({ name: PropTypes.string.isRequired }),
      badgesOrder: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  isInStock: PropTypes.bool,
};

export default MixAndMatchRatingsContainer;
