import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import useEventBus from "../../hooks/useEventBus";
import getWindow from "../../utils/window";
import { setShippingSourcesForProduct } from "../../analytics/pageLoad";
import PropTypes from "prop-types";
import styles from "./DeliveryAndReturns.scss";
import classnamesBind from "classnames/bind";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import {
  getInStockVariants,
  allVariantsPrimarySource,
} from "../../../utils/variantUtils";
import SoldShippedHeader from "./soldShippedHeader/SoldShippedHeader";
import { getProductSourceSellerInfo } from "../../../utils/sourceSellerHelper";
import { useLiveRegion } from "../../context/liveRegion/LiveRegionContext";
import { fireAfsAnalytics } from "../../analytics/pageInteraction";

const classnames = classnamesBind.bind(styles);

const DeliveryAndReturns = ({
  shippingRestrictions,
  productId,
  variants,
  index,
  toggleShippingRestrictions,
}) => {
  const { updateLiveRegion } = useLiveRegion();
  const {
    getTranslation,
    isMixAndMatch,
    deliveryApiEndpoint,
    features: { showReturnsMessage },
    deliveryAndReturnsTrackingValue,
    webContext: { browseCountry },
  } = useRuntimeConstants();
  const inStockVariants = getInStockVariants(variants);
  const [portalReady, setPortalReady] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const selectedOrAvailableVariants = getSelectedOrAvailableVariants(
    selectedVariant,
    inStockVariants
  );
  const {
    isConsistent,
    isPartnerFulfils,
    sourceId,
    sellerId,
    sourceLabel,
    sellerLabel,
    isAllAsosSold,
  } = getProductSourceSellerInfo({
    variants: selectedOrAvailableVariants,
  });
  const deliveryAndReturnsLink = `${deliveryApiEndpoint}${
    deliveryAndReturnsTrackingValue
      ? `#feemsg=${encodeURIComponent(deliveryAndReturnsTrackingValue)}`
      : ""
  }`;

  const updateSelectedVariant = (selectedVariantData) => {
    const variant = selectedVariantData.variantId ? selectedVariantData : null;
    const shouldVariantUpdate =
      !isMixAndMatch || index === selectedVariantData.mixAndMatchProductIndex;

    hasSellerChanged(selectedVariant, selectedVariantData) &&
      updateLiveRegion(getTranslation("dtc_sellerchangemessage_accessibility"));

    if (shouldVariantUpdate) {
      setSelectedVariant(variant);
    }
  };

  const openShippingRestrictionsModal = (event) => {
    toggleShippingRestrictions(
      true,
      shippingRestrictions?.shippingRestrictionsIncludedCountries,
      event.target
    );
  };

  useEffect(() => {
    if (getWindow().asos.pdp.deliveryAndReturnsPortalReady) {
      setPortalReady(true);
    }
  }, []);

  useEventBus(
    "deliveryAndReturnsPortalReady",
    () => {
      setPortalReady(true);
    },
    []
  );

  useEventBus("sizeChanged", updateSelectedVariant, [
    selectedVariant,
    isMixAndMatch,
  ]);

  const getNonGbMessages = () => ({
    termsLink: getTranslation("delivery_and_returns_policy"),
    delivery: isConsistent
      ? `${getTranslation(
          "delivery_information_threshold_message_qualifying_orders1"
        )}.`
      : getTranslation(
          "delivery_information_threshold_message_no_variant_selected"
        ),
  });

  const getNoSelectedVariantMessages = () => ({
    delivery: isAllAsosSold
      ? "Free delivery on orders over £40.00. Standard delivery £4.50"
      : "Select a size to see delivery options.",
    termsLink: "View Delivery & Returns rates",
  });

  const getSelectedVariantMessages = () => ({
    delivery: !isAllAsosSold
      ? `${getTranslation(
          "delivery_information_threshold_message_qualifying_orders1"
        )}.`
      : "Free delivery on orders over £40.00. Standard delivery £4.50",
    termsLink: !isAllAsosSold
      ? "View our Delivery & Returns Policy"
      : "View Delivery & Returns rates",
  });

  const getMessages = () => {
    if (browseCountry !== "GB") {
      return getNonGbMessages();
    }

    if (!selectedVariant) {
      return getNoSelectedVariantMessages();
    }

    return getSelectedVariantMessages();
  };

  const renderDeliveryMessage = () => {
    const { delivery } = getMessages();
    return (
      <div
        data-testid="deliveryAndReturns__deliveryMessage"
        className={classnames(styles.row, styles.deliveryMessage)}
        aria-live="polite"
      >
        <span className={styles.iconContainer}>
          <span className={`${styles.icon} product-delivery-van`} />
        </span>
        {delivery}
      </div>
    );
  };

  const renderReturnsLabel = () =>
    showReturnsMessage && (
      <div
        data-testid="deliveryAndReturns__returnsLabel"
        className={styles.row}
      >
        <span className={classnames(styles.iconContainer)}>
          <span className={`${styles.icon} product-returns-box`} />
        </span>
        {getTranslation(
          isPartnerFulfils ? "returns_message_partner" : "returns_message_asos"
        )}
      </div>
    );

  const renderTerms = () => {
    const { termsLink } = getMessages();
    return (
      <div
        data-testid="deliveryAndReturns__terms"
        className={classnames(styles.row, styles.noIcon, styles.terms)}
      >
        <span>
          <a
            data-testid="deliveryAndReturns__termsLink"
            href={deliveryAndReturnsLink}
            target="_blank"
            aria-label={`${termsLink}. ${getTranslation(
              "generic_opens_new_window"
            )}.`}
            rel="noreferrer"
          >
            <span className={styles.termsLinkText} aria-hidden={true}>
              {termsLink}
              <span className={`${styles.icon} product-new-page-small`} />
            </span>
          </a>
        </span>
      </div>
    );
  };

  const renderShippingRestrictions = () => {
    if (shippingRestrictions.shippingRestrictionsVisible) {
      return (
        <div
          data-testid="deliveryAndReturns__shippingRestrictions"
          className={classnames(styles.row, styles.shippingRestrictions)}
          aria-live="polite"
        >
          <button
            data-testid="deliveryAndReturns__shippingRestrictionsButton"
            onClick={openShippingRestrictionsModal}
          >
            {shippingRestrictions.shippingRestrictionsLabel}
          </button>
        </div>
      );
    }
  };

  const sendAnalytics = () => {
    if (!selectedVariant) {
      setShippingSourcesForProduct({
        fulfillerId: allVariantsPrimarySource(variants)
          ? "primary"
          : sourceId?.toLowerCase(),
        sellerId: sellerId,
        hasShippedByLabel: !!(sellerLabel || sourceLabel),
        productId,
        variants: inStockVariants,
      });
    }
  };

  const renderContent = () => {
    return (
      <div
        className={classnames(styles.container, {
          isMixAndMatch,
        })}
        data-testid="deliveryAndReturns__container"
      >
        <div className={classnames(styles.row, styles.shippingLabel)}>
          <SoldShippedHeader
            mixAndMatchVariants={selectedOrAvailableVariants}
            fireSellerInfoAnalytics={fireAfsAnalytics}
          />
        </div>
        {renderDeliveryMessage()}
        {renderReturnsLabel()}
        {renderTerms()}
        {renderShippingRestrictions()}
      </div>
    );
  };

  sendAnalytics();

  return (
    portalReady &&
    ReactDOM.createPortal(
      renderContent(),
      getWindow().document.getElementById(
        `delivery-and-returns-portal${isMixAndMatch ? `-${index}` : ""}`
      )
    )
  );
};

const variantHasSourceOrSeller = (variant) =>
  variant?.seller || variant?.source;

const hasSellerChanged = (oldValue, newValue) => {
  return !!(
    newValue.variantId &&
    !variantHasSourceOrSeller(newValue) &&
    variantHasSourceOrSeller(oldValue)
  );
};

const getSelectedOrAvailableVariants = (selectedVariant, inStockVariants) =>
  selectedVariant ? [selectedVariant] : inStockVariants;

DeliveryAndReturns.propTypes = {
  variants: PropTypes.arrayOf(PropTypes.object).isRequired,
  shippingRestrictions: PropTypes.object,
  index: PropTypes.number,
  toggleShippingRestrictions: PropTypes.func.isRequired,
};

export default DeliveryAndReturns;
