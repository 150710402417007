import { useEffect, useState } from "react";
import { useRuntimeConstants } from "../context/runtimeConstants/RuntimeConstantsContext";
import { sdk } from "@asosteam/asos-web-adverts-sdk";
import getSdks from "../utils/sdks";
import { onReady, getFeature } from "../utils/features";
import isProduction from "../utils/isProduction";

const useAdverts = () => {
  const [advertsSdkInstance, setSdkInstance] = useState(undefined);
  const { siteDomain, webContext } = useRuntimeConstants();

  useEffect(() => {
    setSdkInstance(
      sdk({
        production: isProduction(siteDomain),
        webContextDelegate: webContext,
        identityDelegatePromise: getSdks()?.identity,
        featuresDelegatePromise: onReady().then(() => ({ getFeature })),
      })
    );
  }, [siteDomain, webContext]);

  return { advertsSdkInstance };
};

export default useAdverts;
