import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import SizeGuideModal from "../../components/sizeGuideModal/SizeGuideModal";
import BackInStockLoginModal from "../../components/backInStock/BackInStockLoginModal";
import LooksModal from "../../components/looks/LooksModal";
import SeeSimilarModal from "../../components/looks/seeSimilarModal/SeeSimilarModal";

export default function AppModals() {
  const { isMixAndMatch, isMobile } = useRuntimeConstants();

  return (
    <>
      <SizeGuideModal />
      {!isMixAndMatch && (
        <>
          {isMobile && <LooksModal />}
          <SeeSimilarModal />
        </>
      )}
      <BackInStockLoginModal />
    </>
  );
}
