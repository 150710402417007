import getWindow from "../../utils/window";
import { getCommons, getGlobalEvars } from "../common";
import { waitForPageLoadAnalytics } from "../pageLoad";
import { getCommonLooksTrackingValues } from "./common";

export const fireSeeSimilarModalOpen = async ({ position, products }) => {
  await waitForPageLoadAnalytics;

  const s = getWindow().s;
  const commons = getCommons();
  const commonLooksTracking = getCommonLooksTrackingValues({ products });
  const seeSimilarEventText = "btl - see similar button";

  s.tl(true, "o", "see similar - pop up page", {
    ...commonLooksTracking,
    eVar60: seeSimilarEventText,
    eVar61: seeSimilarEventText,
    prop6: `${seeSimilarEventText}_${position}`,
    linkTrackVars: commons.setLinkTrackVars([
      ...getGlobalEvars(),
      ...Object.keys(commonLooksTracking),
      "prop6",
    ]),
  });
};
