import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import styles from "./ExpandableText.scss";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

const classnames = classnamesBind.bind(styles);

export const textStyles = {
  PARAGRAPH: "PARAGRAPH",
  DISCLAIMER: "DISCLAIMER",
};

const getFirstWordsToNumber = (text, number) => {
  return text.split(" ").slice(0, number).join(" ");
};

const getCondensedText = ({ text, readMoreText }) => {
  return `${getFirstWordsToNumber(text, 20)}... ${readMoreText}`;
};

const getTextClass = (textStyle) => {
  return {
    [textStyles.PARAGRAPH]: styles.paragraphText,
    [textStyles.DISCLAIMER]: styles.disclaimerText,
  }[textStyle];
};

const ExpandableText = ({
  text,
  hasTitle,
  readMoreTranslationKey,
  testId,
  textStyle = textStyles.PARAGRAPH,
}) => {
  const { getTranslation } = useRuntimeConstants();
  const textElement = useRef();
  const [expandable, setExpandable] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (textElement.current) {
      const { scrollHeight, clientHeight } = textElement.current;

      if (scrollHeight > clientHeight) {
        setExpandable(true);
      }
    }
  }, [textElement]);

  return (
    <>
      {!expandable && (
        <p
          ref={textElement}
          className={classnames(styles.text, getTextClass(textStyle), {
            headerSpace: !hasTitle,
          })}
        >
          {text}
        </p>
      )}
      {expandable && (
        <button
          ref={textElement}
          className={classnames(
            styles.text,
            styles.button,
            getTextClass(textStyle),
            {
              expanded,
              headerSpace: !hasTitle,
            }
          )}
          onClick={() => {
            setExpanded(true);
          }}
          data-testid={testId}
          aria-expanded={expanded}
          aria-label={
            !expanded
              ? getCondensedText({
                  text,
                  readMoreText: getTranslation(readMoreTranslationKey),
                })
              : undefined
          }
        >
          {text}
          {!expanded && (
            <span className={styles.showMoreContainer}>
              <span
                className={classnames(styles.fade, {
                  paragraphFade: textStyle === textStyles.PARAGRAPH,
                  disclaimerFade: textStyle === textStyles.DISCLAIMER,
                })}
              />
              <div className={styles.readMore}>
                {getTranslation(readMoreTranslationKey)}
              </div>
            </span>
          )}
        </button>
      )}
    </>
  );
};

ExpandableText.propTypes = {
  testId: PropTypes.string.isRequired,
  readMoreTranslationKey: PropTypes.string.isRequired,
  hasTitle: PropTypes.bool,
  text: PropTypes.string,
  textStyle: PropTypes.oneOf([textStyles.PARAGRAPH, textStyles.DISCLAIMER]),
};

export default ExpandableText;
