import { useState, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useLooksProducts } from "../../context/looks/LooksProductsContext";
import Image from "../image/Image";
import { getPrimaryImage } from "../../utils/product";
import { useSelectedLooksProduct } from "../../context/looks/SelectedLooksProductContext";
import { useViewport } from "../../context/viewport/ViewportContext";
import { SMALL_VIEWPORT } from "../../constants";
import styles from "./LooksCarousel.scss";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import classnamesBind from "classnames/bind";
import {
  setIntersectionObserver,
  unsetIntersectionObserver,
} from "../../utils/setIntersectionObserver";

const classnames = classnamesBind.bind(styles);

export default function LooksCarousel({ includeHeroProduct }) {
  const { looksProducts } = useLooksProducts();
  const { selectedLooksProduct, setSelectedLooksProduct } =
    useSelectedLooksProduct();
  const { viewportCategory } = useViewport();
  const { getTranslation } = useRuntimeConstants();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [shouldShowRightScrollButton, setShouldShowRightScrollButton] =
    useState(true);
  const imageSize = viewportCategory === SMALL_VIEWPORT ? 56 : 75;
  const tileSize = imageSize + 10;
  const carousel = useRef(null);
  const element = useRef(null);

  let carouselProducts = looksProducts;
  if (!includeHeroProduct) {
    carouselProducts = looksProducts.filter((product) => !product.isHero);
  }

  const handleScrollButton = ({ direction }) => {
    setScrollPosition((scrollPosition) =>
      direction === "right" ? scrollPosition + 1 : scrollPosition - 1
    );
  };

  const handleSelectedLooksProduct = ({ selectedProductId }) => {
    setSelectedLooksProduct(selectedProductId);
  };

  useLayoutEffect(() => {
    const handleLastItemScroll = (observation) => {
      setShouldShowRightScrollButton(!observation?.isIntersecting);
    };

    let observer = null;
    if (element.current && carousel.current) {
      observer = setIntersectionObserver({
        element: element.current,
        options: {
          root: carousel.current,
          threshold: 0,
          rootMargin: `0px -${imageSize}px 0px 0px`,
        },
        handleIntersectionData: handleLastItemScroll,
      });
    }
    return () => {
      unsetIntersectionObserver(element.current, observer);
    };
  }, [carousel, element, imageSize]);

  return (
    <div
      data-testid="looksCarousel"
      className={styles.carousel}
      aria-label={getTranslation("pdp_buy_the_look_carousel_accessibility")}
      ref={carousel}
    >
      {scrollPosition > 0 && (
        <button
          data-testid="leftArrowButton"
          className={classnames(styles.scrollButton, styles.scrollButtonLeft)}
          onClick={() => handleScrollButton({ direction: "left" })}
          aria-label={getTranslation("generic_scroll_previous")}
        >
          <span
            className={classnames(
              "product-chevron-left-outlined",
              styles.navigationButtons
            )}
          ></span>
        </button>
      )}
      <div
        className={styles.carouselThumbnailContainer}
        style={{ right: scrollPosition * tileSize }}
        role="tablist"
        data-testid="looksCarouselItemWrapper"
      >
        {carouselProducts.map(({ id, images, name }, index) => {
          const primaryImage = getPrimaryImage(images);
          const isSelected = id === selectedLooksProduct?.id;

          return (
            <button
              key={id}
              className={classnames(styles.tile, {
                selected: isSelected,
              })}
              onClick={() =>
                handleSelectedLooksProduct({ selectedProductId: id })
              }
              aria-selected={isSelected}
              data-testid="looksCarouselTile"
              role="tab"
              aria-disabled={isSelected}
              ref={carouselProducts.length === index + 1 ? element : null}
            >
              <Image
                additionalClasses={styles.image}
                alternateText={name}
                width={imageSize}
                type="thumbnail"
                url={primaryImage.url}
              />
            </button>
          );
        })}
      </div>
      {shouldShowRightScrollButton && (
        <button
          data-testid="rightArrowButton"
          className={classnames(styles.scrollButton, styles.scrollButtonRight)}
          onClick={() => handleScrollButton({ direction: "right" })}
          hidden={false}
          aria-label={getTranslation("generic_scroll_next")}
        >
          <span
            className={classnames(
              "product-chevron-right-outlined",
              styles.navigationButtons
            )}
          ></span>
        </button>
      )}
    </div>
  );
}

LooksCarousel.propTypes = {
  includeHeroProduct: PropTypes.bool.isRequired,
};
