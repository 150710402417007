function mapReviewPhotos(photos) {
  return photos.map(({ sizes: { thumbnail, normal } }) => ({
    thumbnailUrl: thumbnail.url,
    fullSizeUrl: normal.url,
  }));
}

export function reviewMapper(result) {
  const {
    rating,
    title,
    reviewText,
    submissionRecency,
    syndicationSource,
    badgesOrder,
    photos = [],
  } = result;

  return {
    rating,
    title,
    reviewText,
    submissionRecency,
    syndicationSource,
    badgesOrder,
    photos: mapReviewPhotos(photos),
  };
}
