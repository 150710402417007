import makeRequest from "./makeRequest";
import getRuntimeConstants from "../runtimeConstants";
import {
  LOOKS_RECOMMENDATIONS_HEADER,
  MIGHT_LIKE_API_LIMIT,
} from "../constants";
import { formatUrl, getProductUrl } from "../utils/url";
import { getFeature, onReady } from "../utils/features";
import { getAuthorisationHeader } from "../utils/identity";

const getMightLikeUrl = ({
  brandName,
  name,
  id,
  index,
  type,
  scope,
  isMobile,
}) => {
  const productUrl = getProductUrl(brandName, name, id);
  let ctaref, featureref1;

  if (type === "complementary") {
    ctaref = "complementary+items";
    featureref1 = "complementary+items";
  } else if (scope === LOOKS_RECOMMENDATIONS_HEADER) {
    [ctaref, featureref1] = isMobile
      ? ["btl+pop+up+-+ymal+carousel", "btl+pop+up+ymal+carousel"]
      : [
          "buy+the+look+-+see+similar+product+grid",
          "buy+the+look+-+see+similar+product",
        ];
  } else {
    ctaref = "we+recommend+carousel";
    featureref1 = "we+recommend+pers";
  }

  return `${productUrl}#ctaref-${ctaref}_${index}&featureref1-${featureref1}`;
};

const getHeaderScope = ({ type, scope, isInStock }) => {
  if (scope) {
    return scope;
  }

  if (type === "complementary") {
    return isInStock ? `pdp_comp` : `pdp_comp_oos`;
  }

  return isInStock ? `pdp_ymal` : `pdp_ymal_oos`;
};

const getRequestProperties = ({
  authorisationHeader,
  isInStock,
  type,
  scope,
}) => {
  const { mightLikeApiPath, mightLikeApiTimeout, isMobile } =
    getRuntimeConstants();
  return {
    url: mightLikeApiPath,
    timeout: mightLikeApiTimeout,
    headers: {
      authorization: authorisationHeader,
      "asos-c-recs-scope": getHeaderScope({ type, scope, isInStock }),
    },
    requestType: "MIGHT_LIKE_API",
    apiName: "Presentation",
    caller: type === "complementary" ? "People Also Bought" : "YMAL",
    mapData: (data) => mapRecsData({ data, type, scope, isMobile }),
  };
};

const mapRecsData = ({ data, type, scope, isMobile }) => {
  return data.recommendations.map(
    ({ id, imageUrl, name, brandName, colourWayId, price }, index) => {
      return {
        productId: id,
        url: getMightLikeUrl({
          brandName,
          name,
          id,
          index,
          type,
          scope,
          isMobile,
        }),
        imageUrl: formatUrl(imageUrl),
        productTitle: name,
        brandName,
        colourWayId,
        priceData: price,
      };
    }
  );
};

const getRecsMVT = async () => {
  await onReady();

  const recsAlgorithm = getFeature("pdp-ymal-alg");
  const recsVersion = recsAlgorithm.getVariables()?.tstRecVersion;

  if (recsAlgorithm.isEnabled() && recsVersion !== "") {
    return recsVersion;
  }
};

export default async function getRecommendations({
  isInStock,
  type,
  productId,
  handleErrorReturnValue = () => [],
  scope,
}) {
  const {
    webContext: {
      browseCountry,
      browseCurrency,
      browseLanguage,
      keyStoreDataversion,
      storeId,
    },
    product: { id },
    features: { recommendationsEnabled },
  } = getRuntimeConstants();

  if (!recommendationsEnabled) {
    return [];
  }

  const authorisationHeader = await getAuthorisationHeader();

  const params = {
    productids: productId ? productId : id,
    type: type ? type : "ymal",
    expand: "products",
    limit: MIGHT_LIKE_API_LIMIT,
    store: storeId,
    country: browseCountry,
    currency: browseCurrency,
    lang: browseLanguage,
    keyStoreDataversion,
  };

  const recsMVT = await getRecsMVT();

  if (recsMVT) {
    params["tst-rec-version"] = recsMVT;
  }

  return makeRequest({
    params,
    ...getRequestProperties({ authorisationHeader, isInStock, type, scope }),
    handleErrorReturnValue,
  });
}
