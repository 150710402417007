import { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./RatingsDistribution.scss";
import classnamesBind from "classnames/bind";

const classnames = classnamesBind.bind(styles);
import { getIntlNumberFormat } from "../../utils/format";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
export default function RatingsDistribution({
  ratingDistribution,
  totalReviewCount,
  filterParam,
  language,
  filterByRatingCallback,
}) {
  const { getTranslation } = useRuntimeConstants();
  function getDistributionBar(count) {
    const progression = Math.round((count / totalReviewCount) * 100);
    return (
      <div className={styles.ratingBar}>
        <div className={styles.progress} style={{ width: `${progression}%` }} />
      </div>
    );
  }

  function getRatingsDistributionCounts() {
    return [5, 4, 3, 2, 1].map((ratingsValue) => {
      const distribution = ratingDistribution.find(
        (rating) => ratingsValue === rating.ratingsValue
      );

      return {
        ratingsValue,
        count: distribution ? distribution.count : 0,
      };
    });
  }

  if (ratingDistribution.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.distributionContainer}>
        {getRatingsDistributionCounts().map(({ ratingsValue, count }) => (
          <Fragment key={ratingsValue}>
            <div aria-hidden={true} className={styles.ratingsValue}>
              {getTranslation("rdp_ratings_reviews_distribution_label", {
                smart_count: ratingsValue,
                ratingsValue,
              })}
            </div>
            {getDistributionBar(count)}
            <div aria-hidden={true} className={styles.count}>
              ({getIntlNumberFormat({ language, value: count })})
            </div>
          </Fragment>
        ))}
      </div>
      <div
        className={styles.selectContainer}
        aria-label={getTranslation(
          "rdp_ratings_reviews_accessibility_star_rating_distribution_intro"
        )}
      >
        {getRatingsDistributionCounts().map(({ ratingsValue, count }) => {
          const disabled =
            !count || filterParam === `Rating:eq:${ratingsValue}`;
          return (
            <button
              onClick={filterByRatingCallback(ratingsValue)}
              aria-label={getTranslation(
                "rdp_ratings_reviews_accessibility_star_rating_count",
                {
                  ratingsValue,
                  totalReviewCount,
                  count,
                  smart_count: count,
                }
              )}
              className={classnames(styles.rating, {
                [styles.ratingFocus]: !disabled,
              })}
              key={`ratingSelect-${ratingsValue}`}
              id={`ratingSelect-Rating:eq:${ratingsValue}`}
              disabled={disabled}
            />
          );
        })}
      </div>
    </div>
  );
}

RatingsDistribution.propTypes = {
  ratingDistribution: PropTypes.arrayOf(
    PropTypes.shape({
      ratingsValue: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  totalReviewCount: PropTypes.number.isRequired,
  filterParam: PropTypes.string,
  language: PropTypes.string.isRequired,
  filterByRatingCallback: PropTypes.func.isRequired,
};
