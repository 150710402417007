import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import useElementWhenReady from "../../hooks/useElementWhenReady";
import PaymentPromo from "../../components/paymentPromo/PaymentPromo";
import styles from "./MixAndMatchPaymentPromoContainer.scss";
import { getLowestPriceInStockVariant } from "../../utils/discountPriceData";
import { useStockPrice } from "../../context/stockPrice/StockPriceContext";

const PortalComponent = ({ portalId, price }) => {
  const portalElement = useElementWhenReady(
    `#payment-promo-portal-${portalId}`
  );

  if (!portalElement) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.mixAndMatchPaymentPromoContainer}>
      <PaymentPromo priceValue={price} />
    </div>,
    portalElement
  );
};

PortalComponent.propTypes = {
  price: PropTypes.string.isRequired,
  portalId: PropTypes.string.isRequired,
};

const PaymentPromoPortalsList = ({ products }) => {
  return products.map((singleProduct) => {
    const lowestInStockVariant = getLowestPriceInStockVariant(singleProduct);
    return singleProduct.isInStock ? (
      <PortalComponent
        portalId={singleProduct.id}
        key={singleProduct.id}
        price={lowestInStockVariant.price.current.value}
      />
    ) : null;
  });
};

const MixAndMatchPaymentPromoContainer = () => {
  const { isMixAndMatch } = useRuntimeConstants();
  const { products } = useStockPrice();

  const isMixAndMatchWithProducts = isMixAndMatch && products?.products?.length;

  if (!isMixAndMatchWithProducts) {
    return null;
  }

  return <PaymentPromoPortalsList products={products.products} />;
};

export default MixAndMatchPaymentPromoContainer;
