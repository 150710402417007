import styles from "./RankingDisclaimerMessage.scss";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

export default function RankingDisclaimerMessage() {
  const {
    getTranslation,
    rankingDisclaimerLinks,
    webContext: { storeId },
  } = useRuntimeConstants();

  if (!rankingDisclaimerLinks || !rankingDisclaimerLinks[storeId]) return null;

  return (
    <a
      className={styles.link}
      href={rankingDisclaimerLinks[storeId]}
      target="_blank"
      rel="noreferrer"
      data-testid="rankingDisclaimer"
    >
      {getTranslation("how_we_rank_content")}
      <span className={"product-new-page-small"} />
    </a>
  );
}
