import { useLayoutEffect } from "react";

import { getPrimaryImage } from "../../utils/product";
import OutOfStockHeader from "./outOfStockHeader/OutOfStockHeader";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import MightLike, { RECOMMENDATION_TYPES } from "../mightLike/MightLike";
import { showOutOfStockHero } from "../../utils/edgeFeature";
import { useIsInStockContext } from "../../context/isInStock/IsInStockContext";
import styles from "./OutOfStockHero.scss";
import classnamesBind from "classnames/bind";
import useRecommendations from "../mightLike/useRecommendations";
import getWindow from "../../utils/window";
import Grid from "../grid/Grid";
import {
  Carousel,
  CarouselMode,
} from "@asosteam/asos-web-component-library-carousel";
import getDefaultCarouselSettings from "../../utils/getDefaultCarouselSettings";
import ProductTile from "../productTile/ProductTile";
import { PAGE_AREAS } from "../../constants";
import { buildProductTileAriaLabel } from "../productTile/buildProductTileAriaLabel";
import PriceThenTitle from "../productTile/PriceThenTitle";
import TitleThenPrice from "../productTile/TitleThenPrice";
import useGrid from "../grid/useGrid";
import useDiscountPercentage from "../../hooks/useDiscountPercentage";
import { useViewport } from "../../context/viewport/ViewportContext";
import getRecommendationsTitleAriaLabel from "../mightLike/getRecommendationsTitleAriaLabel";

const classnames = classnamesBind.bind(styles);

function getProductTiles({
  mightLikeProducts,
  gridEnabled,
  showDiscountPercentage,
  getTranslation,
}) {
  return mightLikeProducts.map(
    (
      {
        url,
        productTitle,
        brandName,
        priceData,
        colourWayId,
        productId,
        imageUrl,
      },
      index
    ) => (
      <ProductTile
        key={url}
        url={url}
        title={productTitle}
        colourWayId={colourWayId}
        productId={productId}
        imageUrl={imageUrl}
        index={index}
        pageArea={PAGE_AREAS.MIGHT_LIKE}
        shouldShowTitle={true}
        productTileAriaLabel={buildProductTileAriaLabel({
          getTranslation,
          title: gridEnabled ? brandName : productTitle,
          priceData,
          showDiscountPercentage,
        })}
      >
        {gridEnabled ? (
          <PriceThenTitle priceData={priceData} title={brandName} />
        ) : (
          <TitleThenPrice priceData={priceData} title={productTitle} />
        )}
      </ProductTile>
    )
  );
}

function shouldRender({
  gridEnabled,
  isStockStateReady,
  edgeFeature,
  isInStock,
  mightLikeProducts,
}) {
  return (
    gridEnabled !== null &&
    mightLikeProducts?.length &&
    isStockStateReady &&
    showOutOfStockHero(edgeFeature, isInStock)
  );
}

export default function OutOfStockHero() {
  const { getTranslation, product, edgeFeature, isMobile } =
    useRuntimeConstants();
  const { isStockStateReady, isInStock } = useIsInStockContext();
  const { mightLikeProducts, hasFetchedRecs } = useRecommendations({
    productId: product?.id,
  });
  const { gridEnabled } = useGrid({ isInStock });
  const { showDiscountPercentage } = useDiscountPercentage();
  const { viewportCategory } = useViewport();

  useLayoutEffect(() => {
    if (hasFetchedRecs) {
      const loader = getWindow().document.getElementById(
        "oos-hero-skeleton-recs"
      );

      if (loader) {
        loader.parentElement.removeChild(loader);
      }
    }
  }, [hasFetchedRecs]);

  const primaryImageUrl = getPrimaryImage(product.images).url;

  return shouldRender({
    gridEnabled,
    isStockStateReady,
    edgeFeature,
    isInStock,
    mightLikeProducts,
  }) ? (
    <section
      className={classnames(styles.section, {
        [styles.largeViewport]: !isMobile,
      })}
    >
      <OutOfStockHeader
        title={getTranslation("pdp_out_of_stock_page_title")}
        message={getTranslation("pdp_out_of_stock_page_message")}
        productImageUrl={primaryImageUrl}
        productImageAlt={product.name}
      />
      <MightLike
        title={getTranslation("pdp_carousel_recommendations_out_of_stock")}
        titleAriaLabel={getRecommendationsTitleAriaLabel({
          getTranslation,
          gridEnabled,
          isInStock,
        })}
        type={
          gridEnabled
            ? RECOMMENDATION_TYPES.GRID
            : RECOMMENDATION_TYPES.CAROUSEL
        }
      >
        {gridEnabled ? (
          <Grid ariaDescribedby="mightLikeTitle" gridTestId="mightLikeGrid">
            {getProductTiles({
              mightLikeProducts,
              gridEnabled,
              showDiscountPercentage,
              getTranslation,
            })}
          </Grid>
        ) : (
          <Carousel
            {...getDefaultCarouselSettings({
              getTranslation,
              viewportCategory,
              CarouselMode,
            })}
            ariaDescribedby="mightLikeTitle"
            carouselTestId="mightLikeCarousel"
          >
            {getProductTiles({
              mightLikeProducts,
              gridEnabled,
              showDiscountPercentage,
              getTranslation,
            })}
          </Carousel>
        )}
      </MightLike>
    </section>
  ) : null;
}
