import { useState, useEffect } from "react";
import getWindow from "../utils/window";
import whenElementExists from "../utils/whenElementExists";

const useElementWhenReady = (elementSelector) => {
  const [element, setElement] = useState(null);

  useEffect(() => {
    const root = getWindow().document.querySelector("main");

    whenElementExists(elementSelector, root, setElement);
  }, [elementSelector]);

  return element;
};

export default useElementWhenReady;
