import { getVariantImage } from "../product";

export const dataMapper = (productData, ratings) => {
  return {
    product: {
      name: productData.name,
      images: productData.images,
      contentUrl: productData.media.catwalkUrl,
      startDateTime: productData.variants[0]?.price?.startDateTime,
    },
    aggregateOffer: {
      lowPrice: productData.variants.reduce(
        (acc, cur) => Math.min(acc, cur.price.current.value),
        Infinity
      ),
      highPrice: productData.variants.reduce(
        (acc, cur) => Math.max(acc, cur.price.current.value),
        0
      ),
      currency: productData.variants[0].price.currency,
      isInStock: productData.isInStock,
      offers: productData.variants.map((variant) => ({
        price: variant.price.current.value,
        currency: variant.price.currency,
        isInStock: variant.isInStock,
        product: {
          name: productData.name,
          sku: variant.sku,
          color: variant.colour,
          image: getVariantImage(productData.images, variant).url,
          brand: {
            name: productData.brandName,
          },
        },
      })),
    },
    ratings: Object.keys(ratings)?.length > 0 ? ratings : undefined,
  };
};
