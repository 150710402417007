import styles from "./ProductTileSkeleton.scss";

export default function ProductTileSkeleton() {
  return (
    <div className={styles.skeletonTile} data-testid="skeletonTile">
      <div className={styles.skeletonImage}></div>
      <div className={styles.skeletonPrice}></div>
      <div className={styles.skeletonBrand}></div>
    </div>
  );
}
