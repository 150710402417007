import getWindow from "../../utils/window";
import { clearEvents, setEvents, setLinkTrackEvents } from "../events";
import { getCommons, getGlobalEvars } from "../common";
import { getCommonLooksTrackingValues } from "./common";
import { waitForPageLoadAnalytics } from "../pageLoad";

const trackedProducts = new Set();

export const resetLooksTrackedProducts = () => trackedProducts.clear();

export const fireLooksProductViewTracking = async ({
  looksProducts,
  selectedLooksProduct,
  isMobile,
}) => {
  await waitForPageLoadAnalytics;

  if (trackedProducts.has(selectedLooksProduct.id)) {
    return;
  }
  clearEvents({ trackingName: "looksProductView" });

  const s = getWindow().s;
  const commons = getCommons();

  setEvents({
    events: ["event172"],
    trackingName: "looksProductView",
  });

  const index = looksProducts.indexOf(selectedLooksProduct);
  const btlType = isMobile ? "pop up" : "content";
  s.eVar106 = `btl ${btlType}|product|view_${index}`;
  s.products = `;${selectedLooksProduct.id}`;

  const commonLooksTracking = getCommonLooksTrackingValues({
    products: looksProducts,
  });

  s.tl(true, "o", `btl ${btlType} product view`, {
    ...commonLooksTracking,
    linkTrackVars: commons.setLinkTrackVars([
      ...getGlobalEvars(),
      ...Object.keys(commonLooksTracking),
      "eVar106",
    ]),
    linkTrackEvents: setLinkTrackEvents({ trackingName: "looksProductView" }),
  });
  trackedProducts.add(selectedLooksProduct.id);
};
