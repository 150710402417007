import styles from "./BackToTop.scss";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";

function focusToTopOfPage() {
  document.querySelector("#product-gallery").focus();
}

export default function BackToTop() {
  const { getTranslation } = useRuntimeConstants();

  return (
    <button
      className={styles.button}
      onClick={focusToTopOfPage}
      data-testid="backToTop"
    >
      <span className={`${styles.icon} product-arrowup`} />
      {getTranslation("pdp_misc_back_to_top")}
    </button>
  );
}
