import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Carousel,
  CarouselMode,
} from "@asosteam/asos-web-component-library-carousel";
import {
  clearProducts,
  removeProduct as removeProductFromLocalStorage,
  getProductsInStorage,
  savePageToLocalStorage,
} from "./recentlyViewedLocalStorage";
import getDefaultCarouselSettings from "../../utils/getDefaultCarouselSettings";
import {
  PAGE_AREAS,
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT,
  LARGE_VIEWPORT,
  MAX_RECENTLY_VIEWED_PRODUCTS,
} from "../../constants";
import { fireClearAllAnalytics } from "../../analytics/pageInteraction";
import styles from "./RecentlyViewed.scss";
import ProductTile from "../productTile/ProductTile";
import SquareButton from "../buttons/square/SquareButton";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useLiveRegion } from "../../context/liveRegion/LiveRegionContext";
import { useViewport } from "../../context/viewport/ViewportContext";
import { buildProductTileAriaLabel } from "../productTile/buildProductTileAriaLabel";

export default function RecentlyViewed({ screenHoverable }) {
  const { viewportCategory } = useViewport();
  const { updateLiveRegion } = useLiveRegion();
  const [products, setProducts] = useState(getProductsInStorage);
  const { getTranslation } = useRuntimeConstants();

  useEffect(() => {
    savePageToLocalStorage();
  }, []);

  const removeProductFromState = (productId) => {
    const filteredProducts = products.filter(
      (product) => product.productId !== productId
    );
    setProducts(filteredProducts);
  };

  const handleClearClick = () => {
    updateLiveRegion(
      getTranslation("pdp_carousel_recently_viewed_clear_all_complete")
    );
    clearProducts();
    fireClearAllAnalytics();
    setProducts([]);
    savePageToLocalStorage();
  };

  const handleRemoveClick = (productId, title) => {
    updateLiveRegion(
      `${title} - ${getTranslation(
        "pdp_carousel_recently_viewed_clear_item_complete"
      )}`
    );
    removeProductFromLocalStorage(productId);
    removeProductFromState(productId);
  };

  const getTitleAriaLabel = () => {
    return `${getTranslation(
      "pdp_carousel_recently_viewed_title"
    )}, ${getTranslation("pdp_misc_count_products", {
      total: products.length,
    })}`;
  };

  return (
    products.length > 0 && (
      <section
        id="recentlyViewed"
        className={styles.container}
        aria-label={getTranslation("pdp_carousel_recently_viewed_aria")}
      >
        <div className={styles.subContainer}>
          <header className={styles.header}>
            <h2
              id="recentlyViewedTitle"
              className={styles.title}
              aria-label={getTitleAriaLabel()}
              aria-describedby="recentlyViewedDescription"
            >
              {getTranslation("pdp_carousel_recently_viewed_title")}
            </h2>
            <p className={styles.description} id="recentlyViewedDescription">
              {getTranslation("pdp_carousel_recently_viewed_description")}
            </p>
            <SquareButton
              text={getTranslation("pdp_misc_clear_all")}
              handleClick={handleClearClick}
              additionalClasses={styles.clearButton}
              ariaDescribedby="recentlyViewedTitle"
              testId="recentlyViewedClearAll"
            />
          </header>
          {
            <Carousel
              {...getDefaultCarouselSettings({
                getTranslation,
                viewportCategory,
                CarouselMode,
              })}
              itemsPerRow={getCarouselItemsPerRow(viewportCategory)}
              ariaDescribedby="recentlyViewedTitle"
              carouselTestId="carousel"
            >
              {products.map(
                (
                  {
                    productId,
                    url,
                    imageUrl,
                    productTitle,
                    colourWayId,
                    isMixAndMatch,
                  },
                  index
                ) => (
                  <ProductTile
                    key={url}
                    productId={productId}
                    url={url}
                    imageUrl={imageUrl}
                    title={productTitle}
                    colourWayId={colourWayId}
                    isMixAndMatch={isMixAndMatch}
                    removeFunction={handleRemoveClick}
                    index={index}
                    screenHoverable={screenHoverable}
                    pageArea={PAGE_AREAS.RECENTLY_VIEWED}
                    productTileAriaLabel={buildProductTileAriaLabel({
                      getTranslation,
                      title: productTitle,
                    })}
                  />
                )
              )}
            </Carousel>
          }
        </div>
      </section>
    )
  );
}

function getCarouselItemsPerRow(viewport) {
  return {
    [SMALL_VIEWPORT]: MAX_RECENTLY_VIEWED_PRODUCTS,
    [MEDIUM_VIEWPORT]: 3,
    [LARGE_VIEWPORT]: 4,
  }[viewport];
}

RecentlyViewed.propTypes = {
  screenHoverable: PropTypes.bool.isRequired,
};
