import getWindow from "../../utils/window";
import { getOOSProducts } from "../../utils/product";

import { setEvents, setLinkTrackEvents } from "../events";
import { getCommons, getGlobalEvars } from "../common";
import { getCommonLooksTrackingValues } from "./common";
import { waitForPageLoadAnalytics } from "../pageLoad";

export const LOOKS_POSITION = {
  TOP: "top",
  BOTTOM: "bottom",
};

export const fireLooksModalOpen = async ({ products, position }) => {
  await waitForPageLoadAnalytics;

  const s = getWindow().s;
  const commons = getCommons();

  setEvents({
    events: [
      `event342=${products.length}`,
      `event343=${getOOSProducts(products).length}`,
    ],
    trackingName: "looksModalOpen",
  });

  const commonLooksTracking = getCommonLooksTrackingValues({ products });

  s.tl(true, "o", "btl - pop up page", {
    ...commonLooksTracking,
    prop6: `btl cta ${position}`,
    linkTrackVars: commons.setLinkTrackVars([
      ...getGlobalEvars(),
      ...Object.keys(commonLooksTracking),
      "prop6",
    ]),
    linkTrackEvents: setLinkTrackEvents({ trackingName: "looksModalOpen" }),
  });
};
