import PropTypes from "prop-types";
import LooksImageButton from "./LooksImageButton";
import LooksDesktopSection from "./LooksDesktopSection";
import { useLooksProducts } from "../../context/looks/LooksProductsContext";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import useFiftyPercentInView from "../../hooks/useFiftyPercentInView";
import { fireLooksScrollToView } from "../../analytics/looks/looksScrollToView";

const LooksSection = ({ product }) => {
  const { isMobile } = useRuntimeConstants();
  const { looksProducts } = useLooksProducts();
  const ref = useFiftyPercentInView(() =>
    fireLooksScrollToView({
      products: looksProducts,
      isMobile,
    })
  );

  if (!looksProducts?.length) {
    return null;
  }

  return (
    <div ref={ref}>
      {isMobile ? (
        <LooksImageButton />
      ) : (
        <LooksDesktopSection {...{ product }} />
      )}
    </div>
  );
};

LooksSection.propTypes = {
  product: PropTypes.object.isRequired,
};

export default LooksSection;
