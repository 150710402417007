import makeRequest from "./makeRequest";
import getRuntimeConstants from "../runtimeConstants";

export default function getStores() {
  const {
    webContext: { keyStoreDataversion },
    storeApiPath,
    storeApiTimeout,
  } = getRuntimeConstants();

  return makeRequest({
    url: storeApiPath,
    timeout: storeApiTimeout,
    params: {
      keyStoreDataversion,
    },
    requestType: "STORE_API",
    apiName: "Fashion",
    caller: "Store",
  });
}
