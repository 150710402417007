import PropTypes from "prop-types";
import styles from "./SquareButton.scss";

export default function SquareButton({
  text,
  handleClick,
  additionalClasses,
  ariaDescribedby,
  testId,
}) {
  const classes = additionalClasses
    ? `${styles.button} ${additionalClasses}`
    : styles.button;

  return (
    <button
      className={classes}
      onClick={handleClick}
      aria-describedby={ariaDescribedby}
      data-testid={testId}
    >
      {text}
    </button>
  );
}

SquareButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  additionalClasses: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  testId: PropTypes.string,
};
