import { debounce } from "./debounce";
import getWindow from "./window";

export function setIntersectionObserver({
  element,
  options,
  handleIntersectionData,
  observeIntersectionOnce,
}) {
  const Window = getWindow();
  let observer;

  if (element) {
    observer = new Window.IntersectionObserver(
      debounce((entries) => {
        entries.forEach((entry) => {
          handleIntersectionData(entry, observer, element);
          if (entry.isIntersecting && observeIntersectionOnce) {
            unsetIntersectionObserver(element, observer);
          }
        });
      }, 200),
      options
    );
    observer.observe(element);

    return observer;
  }
}

export function unsetIntersectionObserver(element, observer) {
  if (!!element && !!observer) {
    observer.unobserve(element);
  }
}
