import PropTypes from "prop-types";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import FaceEmoji, { UNAMUSED, DISAPPOINTED } from "../../faceEmoji";

import styles from "./LooksSimilarItemsFailure.scss";

const LooksSimilarItemsFailure = ({
  emojiType,
  titleTranslationKey,
  subTitleTranslationKey,
}) => {
  const { getTranslation } = useRuntimeConstants();

  return (
    <div className={styles.similarItemsFailure}>
      <FaceEmoji type={emojiType} className={styles.faceEmoji} />
      <h2 className={styles.title}>{getTranslation(titleTranslationKey)}</h2>
      <span className={styles.text}>
        {getTranslation(subTitleTranslationKey)}
      </span>
    </div>
  );
};

LooksSimilarItemsFailure.propTypes = {
  emojiType: PropTypes.oneOf([UNAMUSED, DISAPPOINTED]),
  titleTranslationKey: PropTypes.string.isRequired,
  subTitleTranslationKey: PropTypes.string.isRequired,
};

export default LooksSimilarItemsFailure;
