import { LARGE_VIEWPORT, MEDIUM_VIEWPORT, SMALL_VIEWPORT } from "../constants";

const getDefaultCarouselItemsPerRow = (viewport) => {
  return {
    [SMALL_VIEWPORT]: 16,
    [MEDIUM_VIEWPORT]: 3,
    [LARGE_VIEWPORT]: 4,
  }[viewport];
};

const getDefaultCarouselSettings = ({
  getTranslation,
  viewportCategory,
  CarouselMode,
}) => ({
  itemsPerRow: getDefaultCarouselItemsPerRow(viewportCategory),
  carouselTestId: "carousel",
  paginationLabelNoNext: getTranslation("pdp_carousel_pagination_no_next"),
  paginationLabelNoPrevious: getTranslation(
    "pdp_carousel_pagination_no_previous"
  ),
  paginationLabel: getTranslation("pdp_carousel_pagination_label"),
  paginationTabLabel: (lower, upper, total) =>
    getTranslation("pdp_carousel_pagination_tab_label", {
      lowercount: lower,
      uppercount: upper,
      totalcount: total,
    }),
  paginationTabPanelLabel: (lower, upper, total) =>
    getTranslation("pdp_carousel_pagination_tabpanel_label", {
      lowercount: lower,
      uppercount: upper,
      totalcount: total,
    }),
  carouselMode:
    viewportCategory === SMALL_VIEWPORT
      ? CarouselMode.endlessSideScroll
      : CarouselMode.pagination,
});

export default getDefaultCarouselSettings;
