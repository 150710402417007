import PropTypes from "prop-types";
import styles from "./RatingsAndReviews.scss";
import { StarRatingGroup } from "../ratingComponents";
import Review from "../review/Review";
import Recommended from "../recommended/Recommended";
import SubRatings from "../subRatings/SubRatings";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import ExpandableText, { textStyles } from "../expandableText/ExpandableText";
import { REVIEW_DISCLAIMER_STORES } from "../../constants";

function displayDisclaimer(storeId) {
  return REVIEW_DISCLAIMER_STORES.includes(storeId);
}

export default function RatingsAndReviews({
  ratings,
  language,
  openRatingsModal,
}) {
  const {
    averageOverallStarRating,
    averageOverallRating,
    totalReviewCount,
    percentageRecommended,
    mostRecent,
  } = ratings;

  const {
    getTranslation,
    webContext: { storeId },
  } = useRuntimeConstants();

  return (
    <section className={styles.sectionContainer} aria-labelledby="reviewTitle">
      <div className={styles.maxWidthContainer} data-testid="reviewsSection">
        <h2 className={styles.title} id="reviewTitle" tabIndex={-1}>
          {getTranslation("pdp_ratings_reviews_reviews_title")}
        </h2>
        <div className={styles.subContainer}>
          <div className={styles.customerRatings}>
            <StarRatingGroup
              averageOverallStarRating={averageOverallStarRating}
              averageOverallRating={averageOverallRating}
              totalReviewCount={totalReviewCount}
              language={language}
            />
            <Recommended
              percentageRecommended={percentageRecommended}
              language={language}
            />
          </div>
          <div className={styles.customerReviews}>
            <SubRatings subRatings={ratings.subRatings} />
          </div>
        </div>
        <div className={styles.subContainer}>
          <div className={styles.customerReviews}>
            <h3 className={styles.mostRecentReviewTitle}>
              {getTranslation("pdp_ratings_reviews_most_recent_title")}
            </h3>
            <Review {...mostRecent} />
            <button
              className={styles.viewAllReviews}
              onClick={openRatingsModal}
              data-testid="reviewsViewAll"
            >
              {getTranslation("pdp_ratings_reviews_view_all_reviews_cta")}
            </button>
            {displayDisclaimer(storeId) && (
              <ExpandableText
                testId="reviews-disclaimer"
                readMoreTranslationKey="rdp_ratings_reviews_distribution_view_more_cta"
                text={getTranslation("pdp_ratings_review_legalblurb")}
                textStyle={textStyles.DISCLAIMER}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

RatingsAndReviews.propTypes = {
  ratings: PropTypes.shape({
    averageOverallStarRating: PropTypes.number.isRequired,
    averageOverallRating: PropTypes.number.isRequired,
    totalReviewCount: PropTypes.number.isRequired,
    percentageRecommended: PropTypes.number.isRequired,
    subRatings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        minLabel: PropTypes.string.isRequired,
        midLabel: PropTypes.string,
        maxLabel: PropTypes.string.isRequired,
        percentage: PropTypes.number.isRequired,
      }).isRequired
    ).isRequired,
    mostRecent: PropTypes.shape({
      title: PropTypes.string,
      reviewText: PropTypes.string,
      rating: PropTypes.number.isRequired,
      submissionRecency: PropTypes.string.isRequired,
      syndicationSource: PropTypes.shape({ name: PropTypes.string.isRequired }),
      badgesOrder: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  openRatingsModal: PropTypes.func.isRequired,
};
