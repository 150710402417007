export const debounce = (func, wait) => {
  let timeout;

  return (...args) => {
    const callNow = !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      timeout = null;
      if (!callNow) {
        func(...args);
      }
    }, wait);

    if (callNow) {
      func(...args);
    }
  };
};
