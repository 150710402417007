import { useState, useEffect } from "react";
import { PROMISE_PENDING } from "./constants";
import BackToTop from "./components/buttons/backToTop/BackToTop";
import getWindow from "./utils/window";
import Prop65FullWarning from "./components/prop65/Prop65FullWarning";
import AdvertBanner from "./components/advertBanner/AdvertBanner";
import shouldShowProp65 from "./utils/shouldShowProp65";
import { useRuntimeConstants } from "./context/runtimeConstants/RuntimeConstantsContext";
import AppModals from "./containers/appModals/AppModals";
import AppNotificationBar from "./containers/appNotificationBar/AppNotificationBar";
import CarouselsAndReviews from "./containers/carouselsAndReviews/CarouselsAndReviews";
import DeliveryAndReturnsContainer from "./containers/DeliveryAndReturnsContainer/DeliveryAndReturnsContainer";
import ReactPortals from "./containers/reactPortals/ReactPortals";
import IfSingleProduct from "./components/conditional/IfSingleProduct";
import { checkForEdgeError } from "./utils/edgeError";
import { createAndInsertInToPage } from "./utils/schemaOrg/schemaOrg";
import useEventBus from "./hooks/useEventBus";
import {
  fireAddToBagMarketing,
  firePageLoadMarketing,
} from "./utils/marketingTracking/marketingTrackingEvents";
import { updateDisplayedBreadcrumbs } from "./utils/displayBreadcrumbs";
import { useIsInStockContext } from "./context/isInStock/IsInStockContext";
import { useStockPrice } from "./context/stockPrice/StockPriceContext";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";
import { useFeaturesReady } from "./context/featuresReady/FeaturesReadyContext";
import { trackCtaRefInOptimizely } from "./utils/trackCtaRefInOptimizely";

export default function App() {
  const {
    isMixAndMatch,
    product,
    breadcrumbsData,
    siteDomain,
    getTranslation,
    ratings,
  } = useRuntimeConstants();
  const { isInStock } = useIsInStockContext();
  const { products } = useStockPrice();
  const { areFeaturesReady } = useFeaturesReady();

  const [shouldShowProp65State, setShouldShowProp65State] =
    useState(PROMISE_PENDING);

  useEffect(() => {
    updateDisplayedBreadcrumbs({
      breadcrumbsData,
      siteDomain,
      getTranslation,
    });
    checkForEdgeError();
  }, []);

  useEffect(() => {
    if (areFeaturesReady) {
      trackCtaRefInOptimizely();
    }
  }, [areFeaturesReady]);

  useEffect(() => {
    if (products) {
      createAndInsertInToPage({ products, ratings });
    }
  }, [products, ratings]);

  useEffect(() => {
    if (
      !isMixAndMatch &&
      shouldShowProp65State === PROMISE_PENDING &&
      isInStock !== null
    ) {
      shouldShowProp65({ product, isInStock }).then((prop65Shown) => {
        setShouldShowProp65State(prop65Shown);
        getWindow().asos.pdp["prop65Shown"] = prop65Shown;
        getWindow().asos.eventBus.emit("prop65Shown");
      });
    }
  }, [shouldShowProp65State, isMixAndMatch, product, isInStock]);

  useEventBus(
    "fireAddToBagMarketingEvent",
    (variantsAddedList) =>
      fireAddToBagMarketing({ products, variantsAddedList }),
    [products]
  );

  useEventBus(
    "pageLoadAnalyticsFired",
    () => firePageLoadMarketing({ products }),
    [products]
  );

  const renderProp65 = () =>
    shouldShowProp65State === true && <Prop65FullWarning />;

  return (
    <>
      {renderProp65()}
      <CarouselsAndReviews product={product} />
      <BackToTop />
      <IfSingleProduct>
        <ErrorBoundary>
          <AdvertBanner />
        </ErrorBoundary>
      </IfSingleProduct>
      <ReactPortals />
      <AppModals />
      <AppNotificationBar />
      <DeliveryAndReturnsContainer />
    </>
  );
}
