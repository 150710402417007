import LooksSimilarItemsFailure from "./LooksSimilarItemsFailure";
import { DISAPPOINTED } from "../../faceEmoji";

const LooksSimilarItemsFailed = () => (
  <LooksSimilarItemsFailure
    titleTranslationKey="similar_items_fail_title"
    subTitleTranslationKey="similar_items_api_failure_subtitle"
    emojiType={DISAPPOINTED}
  ></LooksSimilarItemsFailure>
);

export default LooksSimilarItemsFailed;
