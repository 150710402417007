import { getPrice } from "../../utils/discountPriceData";

export function buildProductTileAriaLabel({
  getTranslation,
  title,
  priceData,
  showDiscountPercentage,
}) {
  const { priceAriaLabel, percentageDiscountLabel } = getPrice({
    translations: getTranslation,
    priceData,
  });

  let ariaLabel = `${title}.`;
  if (priceAriaLabel) {
    ariaLabel += ` ${priceAriaLabel}`;

    if (showDiscountPercentage) {
      ariaLabel += ` ${percentageDiscountLabel}`;
    }
  }
  return ariaLabel;
}
