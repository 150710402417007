import { useState } from "react";
import PropTypes from "prop-types";
import { VIEW_MORE_OFFSET, VIEW_MORE_ERROR, FILTER_ERROR } from "./constants";
import RatingsAndReviews from "./components/ratingsAndReviews/RatingsAndReviews";
import RatingsAndReviewsModal from "./components/ratingsAndReviewsModal/RatingsAndReviewsModal";
import getReviews from "./api/ratingsApi";
import { fireRatingsAndReviewsOpenAnalytics } from "./analytics/pageInteraction";
import getWindow from "./utils/window";
import { useLiveRegion } from "./context/liveRegion/LiveRegionContext";
import { useRuntimeConstants } from "./context/runtimeConstants/RuntimeConstantsContext";

const DEFAULTS = {
  filterParamDefault: null,
  filterErrorParamDefault: null,
  filteredReviewCountDefault: null,
  filterValueDefault: 0,
  isFilterPendingDefault: false,
  isViewMorePendingDefault: false,
  viewMoreOffsetDefault: VIEW_MORE_OFFSET,
  ratingsApiErrorDefault: false,
  ratingsApiErrorTranslationDefault: null,
};

export default function RatingsAndReviewsApp({ screenHoverable }) {
  const {
    getTranslation,
    webContext,
    ratings: defaultRatings,
  } = useRuntimeConstants();
  const { updateLiveRegion } = useLiveRegion();
  const [isRatingsModalOpen, setIsRatingsModalOpen] = useState(false);
  const [filterParam, setFilterParam] = useState(DEFAULTS.filterParamDefault);
  const [filterErrorParam, setFilterErrorParam] = useState(
    DEFAULTS.filterErrorParamDefault
  );
  const [filteredReviewCount, setFilteredReviewCount] = useState(
    DEFAULTS.filteredReviewCountDefault
  );
  const [filterValue, setFilterValue] = useState(DEFAULTS.filterValueDefault);
  const [isFilterPending, setIsFilterPending] = useState(
    DEFAULTS.isFilterPendingDefault
  );
  const [isViewMorePending, setIsViewMorePending] = useState(
    DEFAULTS.isViewMorePendingDefault
  );
  const [viewMoreOffset, setViewMoreOffset] = useState(
    DEFAULTS.viewMoreOffsetDefault
  );
  const [ratingsApiError, setRatingsApiError] = useState(
    DEFAULTS.ratingsApiErrorDefault
  );
  const [ratingsApiErrorTranslation, setRatingsApiErrorTranslation] = useState(
    DEFAULTS.ratingsApiErrorTranslationDefault
  );
  const [ratings, setRatings] = useState(defaultRatings);
  const [ratingsModalFocusOnClose, setRatingsModalFocusOnClose] =
    useState(null);

  const closeRatingsModal = () => {
    setIsRatingsModalOpen(false);
    setRatingsApiError(false);
  };

  const focusFilter = (filterParam) => {
    getWindow().document.getElementById(
      "ratingsAndReviewsModal"
    ).parentElement.scrollTop = 0;
    const filterElement = getWindow().document.getElementById(
      `ratingSelect-${filterParam}`
    );

    filterElement && filterElement.focus();
  };

  const removeFilter = () => {
    const currentFilterParam = filterParam;

    setFilterParam(DEFAULTS.filterParamDefault);
    setFilterErrorParam(DEFAULTS.filterErrorParamDefault);
    setFilteredReviewCount(DEFAULTS.filteredReviewCountDefault);
    setFilterValue(DEFAULTS.filterValueDefault);
    setIsFilterPending(DEFAULTS.isFilterPendingDefault);
    setIsViewMorePending(DEFAULTS.isViewMorePendingDefault);
    setViewMoreOffset(DEFAULTS.viewMoreOffsetDefault);
    setRatingsApiError(DEFAULTS.ratingsApiErrorDefault);
    setRatingsApiErrorTranslation(DEFAULTS.ratingsApiErrorTranslationDefault);
    setRatings(defaultRatings);

    updateLiveRegion(
      getTranslation("rdp_ratings_reviews_accessibility_filter_removed")
    );

    setTimeout(() => {
      focusFilter(currentFilterParam);
    }, 500);
  };

  const getReviewsCallback = (filterArg) => {
    const offset = filterArg ? 0 : viewMoreOffset;
    const filter = filterArg ? `Rating:eq:${filterArg}` : filterParam;
    setIsFilterPending(Boolean(filterArg));
    setIsViewMorePending(!filterArg);
    setRatingsApiError(false);

    const loadingSpinner =
      getWindow().document.getElementById("loadingSpinner");

    loadingSpinner && loadingSpinner.focus();

    return new Promise((resolve) => {
      getReviews({
        offset,
        filter,
      })
        .then(({ results, totalResults }) => {
          const reviews = filterArg
            ? results
            : [...ratings.reviews, ...results];

          const newFilteredReviewCount = filter ? totalResults : null;

          setRatings({ ...ratings, reviews });
          setIsFilterPending(false);
          setIsViewMorePending(false);
          setViewMoreOffset(offset + VIEW_MORE_OFFSET);
          setFilterParam(filter);
          setFilterErrorParam(null);
          setFilteredReviewCount(newFilteredReviewCount);
          setFilterValue(filterArg || filterValue);

          resolve();
        })
        .catch(() => {
          const error = filter
            ? {
                type: FILTER_ERROR,
                translation: getTranslation(
                  "checkout_api_timeout_dialogue_message"
                ),
              }
            : {
                type: VIEW_MORE_ERROR,
                translation: getTranslation(
                  "pdp_ratings_reviews_reviews_api_error_message"
                ),
              };

          setIsFilterPending(false);
          setIsViewMorePending(false);
          setRatingsApiError(error.type);
          setRatingsApiErrorTranslation(error.translation);
          setFilterErrorParam(filter || null);

          resolve();
        });
    });
  };

  const openRatingsModal = (event) => {
    setIsRatingsModalOpen(true);
    setRatingsModalFocusOnClose(event.target);
    fireRatingsAndReviewsOpenAnalytics(ratings);
  };

  const dismissApiError = () => {
    const currentRatingsApiError = ratingsApiError;
    setRatingsApiError(false);

    if (currentRatingsApiError === FILTER_ERROR) {
      focusFilter(filterErrorParam);
    } else {
      const viewMoreElement =
        getWindow().document.getElementById("viewMoreRatings");

      viewMoreElement && viewMoreElement.focus();
    }
  };

  return (
    <>
      <RatingsAndReviews
        ratings={ratings}
        language={webContext.browseLanguage}
        openRatingsModal={openRatingsModal}
      />
      <RatingsAndReviewsModal
        open={isRatingsModalOpen}
        ratingsModalFocusOnClose={ratingsModalFocusOnClose}
        closeRatingsModal={closeRatingsModal}
        ratings={ratings}
        language={webContext.browseLanguage}
        getReviewsCallback={getReviewsCallback}
        viewMorePending={isViewMorePending}
        filterPending={isFilterPending}
        ratingsApiError={ratingsApiError}
        ratingsApiErrorTranslation={ratingsApiErrorTranslation}
        dismissApiError={dismissApiError}
        filteredReviewCount={filteredReviewCount}
        filterParam={filterParam}
        filterValue={filterValue}
        removeFilter={removeFilter}
        screenHoverable={screenHoverable}
      />
    </>
  );
}

RatingsAndReviewsApp.propTypes = {
  screenHoverable: PropTypes.bool,
};
