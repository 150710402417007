import { useEffect, useState } from "react";
import { fireSaveForLaterMarketing } from "../../utils/marketingTracking/marketingTrackingEvents";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import useElementWhenReady from "../../hooks/useElementWhenReady";
import SaveForLaterButton from "../../components/buttons/saveForLaterButton/SaveForLaterButton";
import saveForLater from "../../utils/saveForLater";
import { PAGE_AREAS } from "../../constants";
import useEventBus from "../../hooks/useEventBus";
import { getVariantFromUrl } from "../../utils/url";
import { getPrimaryVariant } from "../../utils/product";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

function SaveForLaterPortal({ product, ...transferredProps }) {
  const { isMixAndMatch } = useRuntimeConstants();
  const portalElement = useElementWhenReady(
    `#save-for-later-portal-${product.id}`
  );
  const getVariant = getVariantFromUrl(product);

  const defaultItems = product.products || [{ ...product }];
  const hasMultipleProductItems = defaultItems.length > 1;
  const defaultColourWayId = getPrimaryVariant(
    defaultItems[0].variants
  ).colourWayId;
  const preSelectedVariant = isMixAndMatch ? null : getVariant?.variantId;
  const initialItemLookup = defaultItems.reduce(
    (accumulator, product) => mapItem(accumulator, product),
    {}
  );

  const defaultVariantIdentifier = `colourWayId:${defaultColourWayId}`;
  const [itemLookup, setItemLookup] = useState(initialItemLookup);
  const [variantIdentifier, setVariantIdentifier] = useState(
    defaultVariantIdentifier
  );

  const noVariantSelection = (productId) => {
    const productAppendix = hasMultipleProductItems ? `:${productId}` : "";
    setVariantIdentifier(`${defaultVariantIdentifier}${productAppendix}`);
    setItemLookup((itemLookup) => ({
      ...itemLookup,
      [productId]: {
        ...initialItemLookup[productId],
      },
    }));
  };

  const variantSelection = (variantId, productId) => {
    setVariantIdentifier(`variantId:${variantId}`);
    setItemLookup((itemLookup) => {
      const { sellingFast = false } = itemLookup[productId];
      return {
        ...itemLookup,
        [productId]: {
          sellingFast,
          variantId,
          productId,
        },
      };
    });
  };

  useEffect(() => {
    if (preSelectedVariant) {
      variantSelection(preSelectedVariant, product.id);
    }
  }, [preSelectedVariant, product.id]);

  useEventBus(
    "sizeChanged",
    ({ variantId, productId }) => {
      const shouldUpdate = hasMultipleProductItems || productId === product.id;

      if (shouldUpdate) {
        if (variantId) {
          variantSelection(variantId, productId);
        } else {
          noVariantSelection(productId);
        }
      }
    },
    [hasMultipleProductItems]
  );

  const handleSaveClick = () => {
    const items = Object.values(itemLookup);

    saveForLater({
      product,
      items,
      pageArea: PAGE_AREAS.MAIN,
    });

    const selectedVariant = product.variants.find((variant) => {
      return variant.variantId === items[0].variantId;
    });

    fireSaveForLaterMarketing(
      product,
      selectedVariant ? [selectedVariant] : product.variants
    );
  };

  if (!portalElement) return null;

  const keys = hasMultipleProductItems ? { key: variantIdentifier } : {};

  return ReactDOM.createPortal(
    <SaveForLaterButton
      {...keys}
      {...transferredProps}
      handleClick={handleSaveClick}
      saveForLaterClass="mixAndMatchSaveForLater"
      variantIdentifier={variantIdentifier}
      productTitle={product.name}
    />,
    portalElement
  );
}

SaveForLaterPortal.propTypes = {
  transferredProps: PropTypes.object,
  product: PropTypes.object.isRequired,
};

function mapItem(previousValue, { variants, id, sellingFast = false }) {
  return {
    ...previousValue,
    [id]: {
      colourWayId: getPrimaryVariant(variants).colourWayId,
      productId: id,
      sellingFast,
    },
  };
}

export default SaveForLaterPortal;
