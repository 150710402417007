import MightLike, { RECOMMENDATION_TYPES } from "../../mightLike/MightLike";
import useRecommendations from "../../mightLike/useRecommendations";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import { useSelectedLooksProduct } from "../../../context/looks/SelectedLooksProductContext";
import { LOOKS_RECOMMENDATIONS_HEADER, PAGE_AREAS } from "../../../constants";
import Grid from "../../grid/Grid";
import { buildProductTileAriaLabel } from "../../productTile/buildProductTileAriaLabel";
import ProductTile from "../../productTile/ProductTile";
import useDiscountPercentage from "../../../hooks/useDiscountPercentage";
import TitleThenPrice from "../../productTile/TitleThenPrice";
import ProductTileSkeleton from "../../productTile/ProductTileSkeleton";
import Failed from "./LooksSimilarItemsFailed";
import NoItems from "./LooksSimilarItemsNoItems";

function getProductTiles({
  mightLikeProducts,
  showDiscountPercentage,
  getTranslation,
}) {
  return mightLikeProducts.map(
    (
      { url, productTitle, priceData, colourWayId, productId, imageUrl },
      index
    ) => (
      <ProductTile
        key={url}
        url={url}
        title={productTitle}
        colourWayId={colourWayId}
        productId={productId}
        imageUrl={imageUrl}
        index={index}
        pageArea={PAGE_AREAS.LOOKS_SIMILAR_ITEMS}
        shouldShowTitle={true}
        productTileAriaLabel={buildProductTileAriaLabel({
          getTranslation,
          title: productTitle,
          priceData,
          showDiscountPercentage,
        })}
      >
        <TitleThenPrice priceData={priceData} title={productTitle} />
      </ProductTile>
    )
  );
}

const getRecommendationType = (isMobile) => {
  return isMobile
    ? RECOMMENDATION_TYPES.GRID_LOOKS_MOBILE
    : RECOMMENDATION_TYPES.GRID_LOOKS_DESKTOP;
};

export default function LooksSimilarItems() {
  const { getTranslation, isMobile } = useRuntimeConstants();
  const { selectedLooksProduct } = useSelectedLooksProduct();
  const { mightLikeProducts, hasFetchedRecs, apiFailed } = useRecommendations({
    productId: selectedLooksProduct?.id,
    scope: LOOKS_RECOMMENDATIONS_HEADER,
  });
  const { showDiscountPercentage } = useDiscountPercentage();

  if (apiFailed) {
    return <Failed />;
  }

  if (hasFetchedRecs && !mightLikeProducts?.length) {
    return <NoItems />;
  }

  return (
    <MightLike
      title={getTranslation("pdp_carousel_recommendations_title_similar_items")}
      titleAriaLabel={getTranslation(
        "pdp_carousel_recommendations_out_of_stock_aria"
      )}
      id={"looksMightLike"}
      itemCount={mightLikeProducts?.length}
      type={getRecommendationType(isMobile)}
    >
      <Grid
        ariaDescribedby="mightLikeTitle"
        gridTestId="mightLikeGrid"
        type={getRecommendationType(isMobile)}
      >
        {mightLikeProducts?.length
          ? getProductTiles({
              mightLikeProducts,
              showDiscountPercentage,
              getTranslation,
            })
          : [<ProductTileSkeleton key={1} />, <ProductTileSkeleton key={2} />]}
      </Grid>
    </MightLike>
  );
}
