import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./ReviewPhotoGallery.scss";
import getWindow from "../../utils/window";
import classnamesBind from "classnames/bind";
import BrandedSpinner from "../spinner/branded/BrandedSpinner";
import useTrapFocus from "../../hooks/useTrapFocus";
import useEventListener from "../../hooks/useEventListener";
import useTouchControl from "../../hooks/useTouchControl";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

const classnames = classnamesBind.bind(styles);

function getChildNodes(elements) {
  if (Array.from(elements).some((element) => element.childNodes.length)) {
    return Array.from(elements)
      .filter((element) => element.childNodes.length)
      .flatMap((element) => [
        ...element.childNodes,
        ...getChildNodes(element.childNodes),
      ]);
  }
  return [];
}

function movingFirstToLastImage({
  previousImageIndex,
  imageIndex,
  lastImageIndex,
}) {
  return previousImageIndex === 0 && imageIndex === lastImageIndex;
}
function movingLastToFirstImage({
  previousImageIndex,
  imageIndex,
  lastImageIndex,
}) {
  return previousImageIndex === lastImageIndex && imageIndex === 0;
}

export default function ReviewPhotoGallery({
  togglePhotoGallery,
  gallery,
  screenHoverable,
}) {
  const shouldRenderSingleImage =
    gallery?.reviewPhotoList.length === 1 && gallery.atIndex !== null;
  const reviewPhotoListLength = gallery.reviewPhotoList.length;

  const [overlayId] = useState(`overlay${Math.floor(Math.random() * 10000)}`);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageIndex, setImageIndex] = useState(
    gallery?.atIndex ? gallery.atIndex : 0
  );
  const [galleryWidth, setGalleryWidth] = useState(0);
  const [animated, setAnimated] = useState(false);
  const [touchStartPosition, setTouchStartPosition] = useState(null);
  const paginationButtonRefs = useRef([]);
  const chevronButtonRefs = useRef([]);
  const backButtonRef = useRef(null);
  const galleryContainerRef = useRef(null);
  const initialRender = useRef(true);
  const previousIndex = useRef(imageIndex);
  const lastImageIndex = reviewPhotoListLength - 1;
  const { getTranslation } = useRuntimeConstants();

  const handleKeyDown = (event) => {
    if (event.keyCode === 27 || event.key === "escape") {
      togglePhotoGallery(false);
    }
  };

  useEffect(() => {
    const previousImageIndex = previousIndex.current;
    previousIndex.current = imageIndex;

    const setGalleryContainerMarginLeft = ({
      galleryStartPosition,
      index = imageIndex,
    } = {}) => {
      if (shouldRenderSingleImage) {
        return;
      }
      if (galleryStartPosition === undefined) {
        galleryStartPosition = galleryWidth;
      }
      galleryContainerRef.current.style.marginLeft = `-${
        galleryWidth * index + galleryStartPosition
      }px`;

      setTimeout(() => setAnimated(true));
    };

    if (animated || initialRender.current) {
      setGalleryContainerMarginLeft();
      return;
    }

    if (
      movingLastToFirstImage({ previousImageIndex, imageIndex, lastImageIndex })
    ) {
      setGalleryContainerMarginLeft({ galleryStartPosition: 0 });
      return;
    }

    if (
      movingFirstToLastImage({ previousImageIndex, imageIndex, lastImageIndex })
    ) {
      setGalleryContainerMarginLeft({ index: lastImageIndex + 1 });
    }
  }, [
    animated,
    imageIndex,
    lastImageIndex,
    galleryWidth,
    shouldRenderSingleImage,
  ]);

  useEffect(() => {
    backButtonRef.current?.focus();
    const sideModal = getWindow().document.querySelector(
      "[data-testid='sideModal']"
    );
    const sideModalWidth = sideModal.getBoundingClientRect().width;

    setGalleryWidth(sideModalWidth);
  }, []);

  useEffect(() => {
    initialRender.current = false;
  }, []);

  useEventListener("keydown", handleKeyDown, []);

  const setModalRef = useTrapFocus();

  const setPaginationButtonRefs = (element) => {
    if (element) {
      paginationButtonRefs.current.push(element);
      paginationButtonRefs.current.push(...getChildNodes([element]));
    }
  };
  const setChevronButtonRefs = (element) => {
    if (element) {
      chevronButtonRefs.current.push(element);
      chevronButtonRefs.current.push(...getChildNodes([element]));
    }
  };

  const handleNextClick = () => {
    if (imageIndex === lastImageIndex) {
      setAnimated(false);
      setImageIndex(0);
    } else {
      setImageIndex((imageIndex) => imageIndex + 1);
    }
  };

  const handlePreviousClick = () => {
    if (imageIndex === 0) {
      setAnimated(false);
      setImageIndex(lastImageIndex);
    } else {
      setImageIndex((imageIndex) => imageIndex - 1);
    }
  };

  const paginationButtons = () => {
    chevronButtonRefs.current = [];
    return gallery.reviewPhotoList.map((image, index) => {
      const selected = imageIndex === index;
      return (
        <button
          ref={setPaginationButtonRefs}
          key={image.fullSizeUrl}
          className={classnames(styles.paginationButtons)}
          onClick={() => setImageIndex(index)}
          aria-current={selected}
          aria-label={getTranslation("pdp_ratings_review_gallery_image_count", {
            index: index + 1,
            total: reviewPhotoListLength,
          })}
        >
          <div className={classnames(styles.circleButtons, { selected })} />
        </button>
      );
    });
  };

  const mapImage = () => {
    return gallery.reviewPhotoList.map((image, index) => (
      <img
        style={{ width: galleryWidth }}
        key={image.fullSizeUrl}
        className={styles.image}
        data-testid="reviewsPhotoGalleryImage"
        alt={`${getTranslation(
          "rdp_ratings_reviews_accessibility_image_description"
        )}. ${getTranslation("pdp_ratings_review_gallery_image_count", {
          index: index + 1,
          total: reviewPhotoListLength,
        })}`}
        src={image.fullSizeUrl}
        onLoad={() => setImageLoaded(true)}
        aria-hidden={imageIndex !== index}
      />
    ));
  };

  const displayChevrons = () => {
    paginationButtonRefs.current = [];
    return (
      <div>
        <button
          className={classnames(styles.chevron, styles.nextButton)}
          onClick={handleNextClick}
          aria-label={getTranslation("pdp_media_gallery_carousel_next")}
          ref={setChevronButtonRefs}
        >
          <div className={classnames("product-chevron-right-large-outlined")} />
        </button>
        <button
          className={classnames(styles.chevron, styles.previousButton)}
          onClick={handlePreviousClick}
          aria-label={getTranslation("pdp_media_gallery_carousel_previous")}
          ref={setChevronButtonRefs}
        >
          <div className={classnames("product-chevron-left-large-outlined")} />
        </button>
      </div>
    );
  };

  const touchStartHandler = (event) =>
    setTouchStartPosition(
      event.touches.length === 1 ? event.changedTouches[0].clientX : null
    );

  const shouldFireTouchEvent = (event) => {
    const clickableElements = [
      ...paginationButtonRefs.current,
      ...chevronButtonRefs.current,
    ];

    return (
      !clickableElements.includes(event.srcElement) &&
      touchStartPosition !== null
    );
  };

  const touchEndHandler = (event) => {
    if (shouldFireTouchEvent(event)) {
      const endTouchPosition = event.changedTouches[0].clientX;
      const direction = endTouchPosition - touchStartPosition;

      direction <= 0 ? handleNextClick() : handlePreviousClick();
    }

    setTouchStartPosition(null);
  };

  useTouchControl({
    startHandler: touchStartHandler,
    endHandler: touchEndHandler,
  });

  const renderGallery = () => {
    return (
      <div
        ref={galleryContainerRef}
        className={classnames(styles.galleryContainer, {
          animated,
        })}
        style={{
          marginLeft: `-${galleryWidth * (imageIndex + 1)}px`,
        }}
      >
        <img
          style={{ width: galleryWidth }}
          className={styles.image}
          data-testid="reviewsPhotoGalleryImage"
          aria-hidden={true}
          alt={`${getTranslation(
            "rdp_ratings_reviews_accessibility_image_description"
          )}. ${getTranslation("pdp_ratings_review_gallery_image_count", {
            index: reviewPhotoListLength,
            total: reviewPhotoListLength,
          })}`}
          src={gallery.reviewPhotoList[reviewPhotoListLength - 1].fullSizeUrl}
        />
        {mapImage()}
        <img
          style={{ width: galleryWidth }}
          className={styles.image}
          data-testid="reviewsPhotoGalleryImage"
          aria-hidden={true}
          alt={`${getTranslation(
            "rdp_ratings_reviews_accessibility_image_description"
          )}. ${getTranslation("pdp_ratings_review_gallery_image_count", {
            index: 0,
            total: reviewPhotoListLength,
          })}`}
          src={gallery.reviewPhotoList[0].fullSizeUrl}
        />
        {screenHoverable ? (
          displayChevrons()
        ) : (
          <div className={classnames(styles.paginationButtonsContainer)}>
            {paginationButtons()}
          </div>
        )}
      </div>
    );
  };

  const renderImage = () =>
    shouldRenderSingleImage ? (
      <div className={styles.imageContainer}>{mapImage()}</div>
    ) : (
      renderGallery()
    );

  return gallery?.reviewPhotoList.length ? (
    <div className={styles.container} id={overlayId} ref={setModalRef}>
      <button
        className={styles.backBtn}
        data-testid="reviewsPhotoGalleryBackButton"
        aria-label={getTranslation("pdp_misc_back")}
        onClick={() => togglePhotoGallery(false)}
        ref={backButtonRef}
      >
        <div className={"product-left-arrow-outlined"} />
      </button>
      {!imageLoaded && <BrandedSpinner colour={"white"} />}
      {renderImage()}
    </div>
  ) : null;
}

ReviewPhotoGallery.propTypes = {
  togglePhotoGallery: PropTypes.func.isRequired,
  gallery: PropTypes.shape({
    isOpen: PropTypes.bool,
    reviewPhotoList: PropTypes.arrayOf(
      PropTypes.shape({
        fullSizeUrl: PropTypes.string.isRequired,
      })
    ),
    atIndex: PropTypes.number,
  }),
  screenHoverable: PropTypes.bool,
};
