export default function getRecommendationsTitleAriaLabel({
  getTranslation,
  gridEnabled,
  isInStock,
}) {
  if (gridEnabled) {
    return isInStock
      ? getTranslation("pdp_grid_recommendations_aria")
      : getTranslation("pdp_grid_recommendations_out_of_stock_aria");
  }

  return isInStock
    ? getTranslation("pdp_carousel_recommendations_aria")
    : getTranslation("pdp_carousel_recommendations_out_of_stock_aria");
}
