import PropTypes from "prop-types";
import { SecondaryPrice } from "../priceComponents";
import styles from "./TitleThenPrice.scss";

export default function TitleThenPrice({ priceData, title }) {
  return (
    <>
      <h3 className={styles.title}>{title}</h3>
      <SecondaryPrice priceData={priceData} />
    </>
  );
}

TitleThenPrice.propTypes = {
  priceData: PropTypes.shape({
    current: PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
    previous: PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
    rrp: PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
    isMarkedDown: PropTypes.bool,
    isOutletPrice: PropTypes.bool,
  }).isRequired,
  title: PropTypes.string.isRequired,
};
