import PropTypes from "prop-types";
import {
  Carousel,
  CarouselMode,
} from "@asosteam/asos-web-component-library-carousel";
import ProductTile from "../productTile/ProductTile";
import styles from "./BuyTheLook.scss";
import { PAGE_AREAS } from "../../constants";
import getDefaultCarouselSettings from "../../utils/getDefaultCarouselSettings";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useViewport } from "../../context/viewport/ViewportContext";
import { buildProductTileAriaLabel } from "../productTile/buildProductTileAriaLabel";
import TitleThenPrice from "../productTile/TitleThenPrice";

export default function BuyTheLook({ sdks, products, showDiscountPercentage }) {
  const { viewportCategory } = useViewport();
  const { getTranslation } = useRuntimeConstants();
  return (
    <div id="buyTheLook" className={styles.btl_full_width_container}>
      <section
        className={styles.container}
        aria-label={getTranslation("pdp_carousel_style_it_with_aria")}
      >
        <header className={styles.header}>
          <h2 id="buyTheLookTitle" className={styles.title}>
            {getTranslation("pdp_carousel_style_it_with_title")}
          </h2>
        </header>
        <Carousel
          {...getDefaultCarouselSettings({
            getTranslation,
            viewportCategory,
            CarouselMode,
          })}
          ariaDescribedby="buyTheLookTitle"
        >
          {products.map(
            (
              {
                productId,
                url,
                imageUrl,
                productTitle,
                colourWayId,
                priceData,
              },
              index
            ) => (
              <div className={styles.tileWrapper} key={url}>
                <ProductTile
                  productId={productId}
                  colourWayId={colourWayId}
                  url={url}
                  imageUrl={imageUrl}
                  title={productTitle}
                  index={index}
                  sdks={sdks}
                  pageArea={PAGE_AREAS.BUY_THE_LOOK}
                  shouldShowTitle={true}
                  saveForLaterClass={styles.saveForLater}
                  productTileAriaLabel={buildProductTileAriaLabel({
                    getTranslation,
                    title: productTitle,
                    priceData,
                    showDiscountPercentage,
                  })}
                >
                  <TitleThenPrice priceData={priceData} title={productTitle} />
                </ProductTile>
              </div>
            )
          )}
        </Carousel>
      </section>
    </div>
  );
}

BuyTheLook.propTypes = {
  sdks: PropTypes.shape({ savedItems: PropTypes.object.isRequired }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      productTitle: PropTypes.string.isRequired,
      colourWayId: PropTypes.number.isRequired,
      priceData: PropTypes.shape({
        current: PropTypes.shape({
          text: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
  showDiscountPercentage: PropTypes.bool.isRequired,
};
