import PropTypes from "prop-types";

import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

const IfSingleProduct = ({ children }) => {
  const { isMixAndMatch } = useRuntimeConstants();

  return !isMixAndMatch && <>{children}</>;
};

IfSingleProduct.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default IfSingleProduct;
