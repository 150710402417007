import { useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import useElementWhenReady from "../../hooks/useElementWhenReady";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import GalleryButton from "../buttons/galleryButton/GalleryButton";
import { handleModalTriggerEvent } from "./utils/handleModalTriggerEvent";
import { hasAssociatedLooks } from "./utils/hasAssociatedLooks";
import { useLooksProducts } from "../../context/looks/LooksProductsContext";
import { LOOKS_POSITION } from "../../analytics/looks/looksModal";
import { LOOKS_OPEN_MODAL_EVENT } from "./constants.js";
import { handleLooksScroll } from "./utils/handleLooksScroll";
import styles from "./LooksButton.scss";

const LooksButton = () => {
  const [shouldShowLooksButton, setShouldShowLooksButton] = useState(false);
  const mobilePortalElement = useElementWhenReady(`#looks-button-portal`);
  const desktopPortalElement = useElementWhenReady(
    `#looks-button-portal-desktop`
  );
  const { getTranslation, isMobile } = useRuntimeConstants();
  const buttonText = getTranslation("pdp_associated_buy_the_look_title");
  const looksButtonRef = useRef();
  const { looksProducts } = useLooksProducts();

  useEffect(() => {
    if (mobilePortalElement || desktopPortalElement) {
      setShouldShowLooksButton(hasAssociatedLooks({ looksProducts }));
    }
  }, [mobilePortalElement, desktopPortalElement, looksProducts]);

  if (!shouldShowLooksButton) {
    return null;
  }

  const handleClick = (event) => {
    if (isMobile) {
      handleModalTriggerEvent({
        focusOnCloseElement: looksButtonRef.current,
        position: LOOKS_POSITION.TOP,
        eventKey: LOOKS_OPEN_MODAL_EVENT,
      });
    } else {
      handleLooksScroll({ event, looksProducts });
    }
  };

  return createPortal(
    <div className={styles.looksButtonWrapper}>
      <GalleryButton
        ref={looksButtonRef}
        iconClass={"product-hanger"}
        ariaLabel={buttonText}
        buttonText={buttonText}
        handleClick={handleClick}
        dataTestId={"looksButtonHero"}
      />
    </div>,
    isMobile ? mobilePortalElement : desktopPortalElement
  );
};

export default LooksButton;
