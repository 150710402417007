import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useFeaturesReady } from "../../context/featuresReady/FeaturesReadyContext";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import useElementWhenReady from "../../hooks/useElementWhenReady";
import { getFeature } from "../../utils/features";
import classnamesBind from "classnames/bind";
import styles from "./SaveCount.scss";
import { getShortValue, getShortScale } from "../../utils/readableNumbers";
import useEventBus from "../../hooks/useEventBus";
import { setSaveCount } from "../../analytics/pageLoad";
import { useIsInStockContext } from "../../context/isInStock/IsInStockContext";

const classnames = classnamesBind.bind(styles);

export const DEFAULT_SAVE_THRESHOLD = 50;

const SaveCount = () => {
  const { areFeaturesReady } = useFeaturesReady();
  const portalElement = useElementWhenReady(`#image-overlay-slot`);
  const [showSavedBadge, setShowSavedBadge] = useState(false);
  const [saveThreshold, setSaveThreshold] = useState(DEFAULT_SAVE_THRESHOLD);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const { isInStock } = useIsInStockContext();

  useEventBus("videoPlaybackStarted", () => setVideoPlaying(true));
  useEventBus("videoPlaybackStopped", () => setVideoPlaying(false));

  const {
    webContext: { storeId },
    product: { saveCount },
    getTranslation,
  } = useRuntimeConstants();

  useEffect(() => {
    if (areFeaturesReady && isInStock === true) {
      const feature = getFeature("pdp-save-count");
      setShowSavedBadge(feature.isEnabled());
      setSaveThreshold(
        feature.getVariables()?.thresholdSavesToShow || DEFAULT_SAVE_THRESHOLD
      );
    }
  }, [areFeaturesReady, isInStock]);

  const getSaveCountString = (value) => {
    switch (getShortScale(value, storeId)) {
      case "K":
        return getTranslation("pdp_saved_count_social_proofing_thousands", {
          saveCount: getShortValue(value, storeId),
        });
      case "M":
        return getTranslation("pdp_saved_count_social_proofing_millions", {
          saveCount: getShortValue(value, storeId),
        });
      default:
        return getShortValue(value, storeId);
    }
  };

  saveCount.value = saveCount.value || 0;

  const shouldHideSaveCount = () =>
    !portalElement ||
    !showSavedBadge ||
    saveCount.value < saveThreshold ||
    !saveCount.canShowSaveCount ||
    videoPlaying;

  if (shouldHideSaveCount()) {
    return null;
  }

  setSaveCount(saveCount.value);

  return ReactDOM.createPortal(
    <div
      className={styles.saveCountWrapper}
      role="region"
      aria-label={getTranslation("pdp_saved_count_accessibility", {
        saveCount: saveCount.value,
      })}
    >
      <span aria-hidden={true} className={styles.saveCountvalue}>
        {getSaveCountString(saveCount.value)}
      </span>
      <span
        aria-hidden={true}
        className={classnames(
          "product-saved-for-later-count",
          styles.saveCountHeartIcon
        )}
      />
    </div>,
    portalElement
  );
};

export default SaveCount;
