import { useRef } from "react";
import PropTypes from "prop-types";
import { StarRating } from "../ratingComponents";
import styles from "./Review.scss";
import classnamesBind from "classnames/bind";
import ExpandableText from "../expandableText/ExpandableText";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

const classnames = classnamesBind.bind(styles);

const SYNDICATED_REVIEW = "syndicatedReview";
const STAFF_REVIEW = "staffReview";
const VERIFIED_REVIEW = "verifiedReview";

const Review = ({
  title,
  reviewText,
  rating,
  submissionRecency,
  syndicationSource,
  badgesOrder,
  photos,
  additionalClasses,
  displayPhotos,
  togglePhotoGallery,
}) => {
  const { getTranslation } = useRuntimeConstants();

  const reviewSource = getReviewSource(syndicationSource, badgesOrder);
  const ratingsAriaLabel = useRef(
    getRatingAriaLabel(getTranslation, {
      submissionRecency,
      syndicationSource,
      rating,
      reviewSource,
    })
  );
  const ratingsText = useRef(
    getSourceText(getTranslation, {
      syndicationSource,
      rating,
      reviewSource,
    })
  );

  return (
    <article
      className={additionalClasses}
      aria-label={ratingsAriaLabel.current}
    >
      <div
        className={styles.rating}
        tabIndex={-1}
        data-testid="reviewsModalReview"
      >
        <div className={styles.starsAndRecencyContainer}>
          <StarRating rating={rating} />
          <span className={styles.recency} aria-hidden={true}>
            {submissionRecency}
          </span>
        </div>
        <p className={styles.source} aria-hidden={true}>
          {ratingsText.current}
        </p>
      </div>
      {title && (
        <h4
          className={classnames(styles.title, styles.headerSpace, {
            contentSpace: !!reviewText,
          })}
        >
          {title}
        </h4>
      )}
      {reviewText && (
        <ExpandableText
          hasTitle={!!title}
          text={reviewText}
          readMoreTranslationKey="rdp_ratings_reviews_read_more"
          testId="reviewsReadMore"
        />
      )}
      {shouldDisplayPhotos({ photos, displayPhotos, togglePhotoGallery }) && (
        <div className={styles.thumbnails}>
          {photos.map(({ thumbnailUrl }, index) => (
            <button
              data-testid="reviewsModalThumbnailButton"
              className={styles.thumbnail}
              key={thumbnailUrl}
              aria-label={getTranslation(
                "pdp_media_gallery_thumbnail_instruction"
              )}
              onClick={(event) =>
                togglePhotoGallery(true, photos, index, event.target)
              }
            >
              <img
                className={styles.thumbnailImage}
                src={thumbnailUrl}
                loading="lazy"
                alt={getTranslation(
                  "rdp_ratings_reviews_accessibility_image_description"
                )}
              />
            </button>
          ))}
        </div>
      )}
    </article>
  );
};

Review.propTypes = {
  title: PropTypes.string,
  reviewText: PropTypes.string,
  rating: PropTypes.number.isRequired,
  submissionRecency: PropTypes.string.isRequired,
  syndicationSource: PropTypes.shape({ name: PropTypes.string.isRequired }),
  badgesOrder: PropTypes.array.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      thumbnailUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  additionalClasses: PropTypes.string,
  displayPhotos: PropTypes.bool,
  togglePhotoGallery: PropTypes.func,
};

const shouldDisplayPhotos = ({ photos, displayPhotos, togglePhotoGallery }) =>
  !!(displayPhotos && togglePhotoGallery && photos.length);

const getReviewSource = (syndicationSource, badgesOrder) => {
  if (syndicationSource) {
    return SYNDICATED_REVIEW;
  }
  if (badgesOrder.length) {
    if (badgesOrder.includes("Staff")) {
      return STAFF_REVIEW;
    }
    return VERIFIED_REVIEW;
  }
};

const getSourceText = (
  translation,
  { syndicationSource = null, reviewSource = null }
) => {
  return {
    [SYNDICATED_REVIEW]: translation(
      "pdp_ratings_reviews_syndication_badge_text",
      {
        syndicationSourceName: syndicationSource?.name,
      }
    ),
    [STAFF_REVIEW]: translation(
      "pdp_ratings_reviews_verified_staff_purchase_badge_text"
    ),
    [VERIFIED_REVIEW]: translation(
      "pdp_ratings_reviews_verified_purchaser_badge_text"
    ),
  }[reviewSource];
};

const getRatingAriaLabel = (
  translation,
  { submissionRecency, syndicationSource, rating, reviewSource }
) => {
  if (reviewSource === SYNDICATED_REVIEW) {
    translation(
      "pdp_ratings_reviews_accessibility_individual_review_intro_syndicated",
      {
        submissionRecency: submissionRecency,
        syndicationSourceName: syndicationSource.name,
        rating: rating,
      }
    );
  }

  translation(
    "pdp_ratings_reviews_accessibility_individual_review_intro_verified_purchaser",
    {
      submissionRecency: submissionRecency,
      badgeName: getSourceText(translation, {
        syndicationSource,
        reviewSource,
      }),
      rating: rating,
    }
  );
};

export default Review;
