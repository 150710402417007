import { useState, useRef } from "react";
import { MODAL_TYPE } from "../../constants";
import Modal from "../modal/Modal";
import styles from "./SizeGuideModal.scss";
import useEventBus from "../../hooks/useEventBus";
import useEventListener from "../../hooks/useEventListener";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

export default function SizeGuideModal() {
  const modalId = `${MODAL_TYPE.SIDE}${Math.floor(Math.random() * 10000)}`;

  const [open, setOpen] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [sizeGuideUrl, setSizeGuideUrl] = useState(null);
  const [focusOnCloseElement, setFocusOnCloseElement] = useState(null);

  const { getTranslation } = useRuntimeConstants();
  const iframeRef = useRef(null);

  const handleIframeMessage = ({ data }) => {
    if (data === "sizeGuides-closeButton") {
      closeModal();
    }
    if (data === "sizeGuides-ready") {
      setContentLoaded(true);
    }
  };
  const closeModal = () => setOpen(false);

  const focusCloseButton = () => {
    iframeRef?.current.contentWindow.postMessage(
      "size-guides-focus-close",
      "*"
    );
  };

  useEventBus(
    "sizeGuideOpen",
    ({ url, focusOnClose }) => {
      setOpen(true);
      setSizeGuideUrl(url);
      setFocusOnCloseElement(focusOnClose);

      if (iframeRef?.current) {
        focusCloseButton();
      }
    },
    []
  );
  useEventListener("message", handleIframeMessage, []);

  return (
    <Modal
      modalType={MODAL_TYPE.SIDE}
      modalId={modalId}
      open={open}
      closeModal={closeModal}
      focusOnClose={focusOnCloseElement}
      hideCloseButton
      contentLoaded={contentLoaded}
      withScrollBar={false}
      useFocusTrapCatchAll={true}
    >
      <iframe
        data-testid="size-guide-iframe"
        seamless="seamless"
        title={getTranslation("pdp_size_guide_aria_label")}
        src={sizeGuideUrl}
        ref={iframeRef}
        className={styles.iframe}
        onLoad={() => {
          focusCloseButton();
        }}
      />
    </Modal>
  );
}
