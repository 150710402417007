import { useRef, useState } from "react";
import {
  MODAL_TYPE,
  BACK_IN_STOCK_SELECTED_VARIANT,
  BACK_IN_STOCK_SELECTED_PRODUCT,
  BACK_IN_STOCK_NOTIFY_MODAL_COPY_KEY,
  BACK_IN_STOCK_TURN_OFF_ALERT_MODAL_COPY_KEY,
} from "../../constants";
import Modal from "../modal/Modal";
import Button from "../buttons/button/Button";
import useEventBus from "../../hooks/useEventBus";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { loginRedirect } from "../../utils/identity";
import { setSessionStorageItem } from "../../utils/storage";

import styles from "./BackInStockLoginModal.scss";
import { BUTTON_TYPES } from "../buttons/button/ButtonTypes";
import FaceEmoji, { DOH } from "../faceEmoji";

const BackInStockLoginModal = () => {
  const focusOnCloseElement = useRef(null);
  const [modalParameters, setModalParameters] = useState(null);
  const { getTranslation } = useRuntimeConstants();

  const closeModal = () => {
    setModalParameters(null);
  };

  useEventBus(
    "loginModalOpen",
    (eventData) => {
      const { focusOnCloseElement: eventFocusElement, ...restOfEventData } =
        eventData;
      focusOnCloseElement.current = eventFocusElement;
      setModalParameters(restOfEventData);
    },
    []
  );

  if (!modalParameters) {
    return null;
  }

  const copyConfig = {
    [BACK_IN_STOCK_NOTIFY_MODAL_COPY_KEY]: {
      signInButtonCopy: getTranslation("back_in_stock_sign_in_modal_button"),
      signInTitleCopy: getTranslation("back_in_stock_sign_in_modal_heading"),
      signInTextCopy: `${getTranslation(
        "back_in_stock_sign_in_modal_title"
      )}. ${getTranslation("back_in_stock_sign_in_modal_text")}`,
    },
    [BACK_IN_STOCK_TURN_OFF_ALERT_MODAL_COPY_KEY]: {
      signInButtonCopy: getTranslation(
        "pdp_login_box_session_timeout_sign_in_button"
      ),
      signInTitleCopy: getTranslation(
        "pdp_login_box_turn_off_alert_sign_in_title"
      ),
      signInTextCopy: getTranslation("pdp_login_session_expiry_message"),
    },
  };
  const { copyKey, selectedVariantId, selectedProductId } = modalParameters;

  const setBackInStockSessionStorage = () => {
    if (selectedProductId) {
      setSessionStorageItem(BACK_IN_STOCK_SELECTED_PRODUCT, selectedProductId);
    }
    if (selectedVariantId) {
      setSessionStorageItem(BACK_IN_STOCK_SELECTED_VARIANT, selectedVariantId);
    }
  };

  const backInStockSignIn = async () => {
    setBackInStockSessionStorage();
    if (selectedVariantId) {
      loginRedirect();
    }
  };

  const { signInButtonCopy, signInTitleCopy, signInTextCopy } =
    copyConfig[copyKey];

  return (
    <Modal
      contentLoaded={true}
      modalType={MODAL_TYPE.CENTER}
      open={!!modalParameters}
      closeModal={closeModal}
      modalId="BackInStockLoginModal"
      forceDOMRewrite={true}
      focusOnClose={focusOnCloseElement.current}
      withScrollBar={false}
    >
      <div className={styles.container}>
        <FaceEmoji type={DOH} className={styles.faceEmoji} />
        <h2 className={styles.title}>{signInTitleCopy}</h2>
        <p className={styles.text}>{signInTextCopy}</p>
        <Button
          handleClick={backInStockSignIn}
          aria-label={signInButtonCopy}
          type={BUTTON_TYPES.primary}
          additionalClasses={styles.button}
        >
          {signInButtonCopy}
        </Button>
      </div>
    </Modal>
  );
};

export default BackInStockLoginModal;
