import Icon from "../icon/Icon";
import PropTypes from "prop-types";
import styles from "./FaceEmoji.scss";
import classnames from "classnames";
import * as types from "./constants";

const FaceEmoji = ({ type, className }) => (
  <div className={classnames(styles.faceEmoji, className)}>
    <Icon id={"emoji-face"} className={styles.face} />
    <Icon id={`emoji-${type}`} className={styles.features} />
  </div>
);

FaceEmoji.propTypes = {
  type: PropTypes.oneOf(Object.values(types)),
  className: PropTypes.string,
};

export default FaceEmoji;
