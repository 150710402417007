import LooksSimilarItemsFailure from "./LooksSimilarItemsFailure";
import { UNAMUSED } from "../../faceEmoji";

const LooksSimilarItemsNoItems = () => (
  <LooksSimilarItemsFailure
    titleTranslationKey="similar_items_no_results_title"
    subTitleTranslationKey="similar_items_no_results_subtitle"
    emojiType={UNAMUSED}
  />
);

export default LooksSimilarItemsNoItems;
