import getWindow from "../../utils/window";
import { getOOSProducts } from "../../utils/product";
import { getCommons, getGlobalEvars } from "../common";
import { setEvents, setLinkTrackEvents } from "../events";
import { waitForPageLoadAnalytics } from "../pageLoad";
import { getCommonLooksTrackingValues } from "./common";

export let defuseScrollIntoViewTracking = false;

export const fireLooksButtonClickScrollTracking = async ({ products }) => {
  await waitForPageLoadAnalytics;

  defuseScrollIntoViewTracking = true;

  const s = getWindow().s;
  const commons = getCommons();
  const BTL_BTN_SCROLL_CLICK = "looksButtonScroll";
  const nonHeroProducts = products.filter(({ isHero }) => !isHero);
  const looksProductsIds = nonHeroProducts.map(({ id }) => id);

  s.products = looksProductsIds.map((id) => `;${id};;;;`).join(",");

  setEvents({
    events: [
      `event342=${products.length}`,
      `event343=${getOOSProducts(products).length}`,
    ],
    trackingName: BTL_BTN_SCROLL_CLICK,
  });

  const commonLooksTracking = getCommonLooksTrackingValues({ products });

  s.tl(true, "o", "btl PDP content scroll", {
    ...commonLooksTracking,
    prop6: "btl cta",
    linkTrackVars: commons.setLinkTrackVars([
      ...getGlobalEvars(),
      ...Object.keys(commonLooksTracking),
      "prop6",
    ]),
    linkTrackEvents: setLinkTrackEvents({ trackingName: BTL_BTN_SCROLL_CLICK }),
  });
};
