import getWindow from "../window";

export default function scrollIntoView(elementToScrollToId, block = "center") {
  const supportsNativeSmoothScroll =
    "scrollBehavior" in getWindow().document.documentElement.style;

  const getElement = getWindow().document.getElementById(elementToScrollToId);

  getElement.scrollIntoView({
    behavior: supportsNativeSmoothScroll ? "smooth" : "instant",
    block,
  });
}
