import { useEffect, useState } from "react";
import getRecommendations from "../../api/mightLikeApi";
import { useIsInStockContext } from "../../context/isInStock/IsInStockContext";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

export default function useRecommendations({ productId, scope }) {
  const { isStockStateReady, isInStock } = useIsInStockContext();
  const { isMixAndMatch } = useRuntimeConstants();
  const [mightLikeProducts, setMightLikeProducts] = useState(null);
  const [hasFetchedRecs, setHasFetchedRecs] = useState(false);
  const [apiFailed, setApiFailed] = useState(false);
  const [fetchedProductId, setFetchedProductId] = useState(null);

  useEffect(() => {
    if (isMixAndMatch || !isStockStateReady || !productId) {
      return;
    }

    getRecommendations({
      isInStock,
      scope,
      productId,
      handleErrorReturnValue: () => {
        setApiFailed(true);
      },
    }).then((products) => {
      setMightLikeProducts(products);
      setHasFetchedRecs(true);
      setFetchedProductId(productId);
    });
  }, [productId, isMixAndMatch, isStockStateReady, isInStock, scope]);

  useEffect(() => {
    if (productId !== fetchedProductId) {
      setApiFailed(false);
      setHasFetchedRecs(false);
      setMightLikeProducts(null);
    }
  }, [productId, fetchedProductId]);

  return { mightLikeProducts, hasFetchedRecs, apiFailed };
}
