import PropTypes from "prop-types";
import BuyTheLook from "../../components/buyTheLook/BuyTheLook";
import YouMightAlsoLike from "../../components/youMightAlsoLike/YouMightAlsoLike";
import RatingsAndReviewsApp from "../../RatingsAndReviewsApp";
import RecentlyViewed from "../../components/recentlyViewed/RecentlyViewed";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useFeaturesReady } from "../../context/featuresReady/FeaturesReadyContext";
import { useIsInStockContext } from "../../context/isInStock/IsInStockContext";
import useRatingsFeatureRequirements from "../../hooks/ratingsFeatureRequirements/useRatingsFeatureRequirements";
import { showOutOfStockHero } from "../../utils/edgeFeature";
import getSdks from "../../utils/sdks";
import getWindow from "../../utils/window";
import PeopleAlsoBought from "../../components/peopleAlsoBought/PeopleAlsoBought";
import LooksSection from "../../components/looks/LooksSection";
import { useLooksProducts } from "../../context/looks/LooksProductsContext";
import useDiscountPercentage from "../../hooks/useDiscountPercentage";
import useBuyTheLookProducts from "./useBuyTheLookProducts";
import useRecommendationProducts from "./useRecommendationProducts";

export default function CarouselsAndReviews({ product }) {
  const { isStockStateReady, isInStock } = useIsInStockContext();
  const { areFeaturesReady } = useFeaturesReady();
  const { isMixAndMatch, edgeFeature, ratings } = useRuntimeConstants();
  const { showDiscountPercentage } = useDiscountPercentage();
  const ratingsFeatureRequirementsAchieved = useRatingsFeatureRequirements(
    ratings,
    isInStock
  );
  function isScreenHoverable() {
    return getWindow().matchMedia("(hover: hover)").matches;
  }
  const screenHoverable = isScreenHoverable();
  const { showUpSell } = product;
  const { shouldShowLooksSection } = useLooksProducts();
  const recommendationProducts = useRecommendationProducts({
    showUpSell,
    isInStock,
    isStockStateReady,
    areFeaturesReady,
    isMixAndMatch,
  });
  const buyTheLookProducts = useBuyTheLookProducts({
    showUpSell,
    areFeaturesReady,
    isMixAndMatch,
    shouldShowLooksSection,
  });

  const renderBuyTheLook = () =>
    buyTheLookProducts.length > 0 && (
      <BuyTheLook
        sdks={getSdks()}
        products={buyTheLookProducts}
        showDiscountPercentage={showDiscountPercentage}
      />
    );

  const renderPeopleAlsoBought = () =>
    recommendationProducts.length > 0 && (
      <PeopleAlsoBought
        sdks={getSdks()}
        products={recommendationProducts}
        showDiscountPercentage={showDiscountPercentage}
      />
    );

  const renderRatingsAndReviewsApp = () =>
    !isMixAndMatch &&
    ratingsFeatureRequirementsAchieved &&
    ratings?.displayRatingsSection && (
      <RatingsAndReviewsApp screenHoverable={screenHoverable} />
    );

  const renderMightLike = () =>
    showUpSell &&
    isStockStateReady &&
    !showOutOfStockHero(edgeFeature, isInStock) && <YouMightAlsoLike />;

  const renderLooksSection = () =>
    showUpSell && shouldShowLooksSection && <LooksSection product={product} />;

  const renderRecentlyViewed = () =>
    showUpSell && <RecentlyViewed screenHoverable={screenHoverable} />;

  return (
    <div>
      {renderMightLike()}
      {renderLooksSection()}
      {renderPeopleAlsoBought()}
      {renderBuyTheLook()}
      {renderRatingsAndReviewsApp()}
      {renderRecentlyViewed()}
    </div>
  );
}

CarouselsAndReviews.propTypes = {
  product: PropTypes.object.isRequired,
};
