import { useCallback } from "react";
import PropTypes from "prop-types";
import styles from "./Toast.scss";
import classnamesBind from "classnames/bind";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

const classnames = classnamesBind.bind(styles);

export default function Toast({ children, close, ariaLabelledBy }) {
  const { getTranslation } = useRuntimeConstants();
  const closeButtonRef = useCallback((node) => node && node.focus(), []);

  return (
    <div
      className={styles.toast}
      role="alertdialog"
      aria-labelledby={ariaLabelledBy}
    >
      <button
        ref={closeButtonRef}
        className={styles.closeButton}
        onClick={close}
        aria-label={getTranslation("pdp_misc_close")}
      >
        <div className={classnames(styles.closeIcon, "product-closesmall")} />
      </button>
      <div className={classnames(styles.icon, "product-icon-exclamation")} />
      <div className={styles.content}>{children}</div>
    </div>
  );
}

Toast.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  ariaLabelledBy: PropTypes.string.isRequired,
};
