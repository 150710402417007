import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import styles from "./SubRatings.scss";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import RatingBar from "../ratingBar/RatingBar";
import { getSubRatingIcon } from "../../utils/getSubRatingIcon";

const classnames = classnamesBind.bind(styles);

export default function SubRatings({ subRatings, additionalClasses }) {
  const { getTranslation } = useRuntimeConstants();

  if (subRatings.length === 0) {
    return null;
  }

  return (
    <div className={classnames(additionalClasses)}>
      <h3 className={styles.title}>
        {getTranslation("pdp_ratings_reviews_customer_rating_title")}
      </h3>
      {subRatings.map(
        ({ id, label, minLabel, midLabel, maxLabel, percentage }) => (
          <div key={id} className={styles.subRating}>
            <div className={styles.titleRow}>
              <div className={classnames(styles.icon, getSubRatingIcon(id))} />
              <h4 className={styles.subRatingTitle}>{label}:</h4>
            </div>
            <RatingBar
              id={id}
              label={label}
              minLabel={minLabel}
              midLabel={midLabel}
              maxLabel={maxLabel}
              percentage={percentage}
            />
          </div>
        )
      )}
    </div>
  );
}

SubRatings.propTypes = {
  subRatings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      minLabel: PropTypes.string.isRequired,
      midLabel: PropTypes.string,
      maxLabel: PropTypes.string.isRequired,
      percentage: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  additionalClasses: PropTypes.string,
};
