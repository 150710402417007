import { useEffect, useState } from "react";
import { useFeaturesReady } from "../../context/featuresReady/FeaturesReadyContext";
import { getFeature } from "../../utils/features";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

export default function useGrid({ isInStock }) {
  const [gridEnabled, setGridEnabled] = useState(null);
  const { areFeaturesReady } = useFeaturesReady();
  const { urlParams } = useRuntimeConstants();
  const affiliateId = urlParams?.affid ?? null;

  useEffect(() => {
    if (areFeaturesReady) {
      setGridEnabled(
        getFeature("pdp-rec-grid", null, {
          affiliateId,
          isInStock,
        }).isEnabled()
      );
    }
  }, [affiliateId, isInStock, areFeaturesReady]);

  return { gridEnabled };
}
