import { waitForPageLoadAnalytics } from "../pageLoad";
import getWindow from "../../utils/window";
import { getOOSProducts } from "../../utils/product";
import { getCommons, getGlobalEvars } from "../common";
import { setEvents, setLinkTrackEvents } from "../events";
import { getCommonLooksTrackingValues } from "./common";
import { defuseScrollIntoViewTracking } from "./looksButtonClickScroll";

export const fireLooksScrollToView = async ({ products, isMobile }) => {
  if (defuseScrollIntoViewTracking) {
    return;
  }

  await waitForPageLoadAnalytics;

  const s = getWindow().s;
  const commons = getCommons();
  const TRACKING_NAME = isMobile ? "looksCtaView" : "looksContentView";
  const TRACKING_AREA = isMobile ? "CTA" : "content";

  let events = ["event172"];

  if (!isMobile) {
    events = [
      ...events,
      `event342=${products.length}`,
      `event343=${getOOSProducts(products).length}`,
    ];
  }

  setEvents({
    events,
    trackingName: TRACKING_NAME,
  });

  s.eVar106 = `btl|${TRACKING_AREA}|impression`;

  const commonLooksTracking = getCommonLooksTrackingValues({ products });

  s.tl(true, "o", `btl ${TRACKING_AREA.toLowerCase()} impression`, {
    ...commonLooksTracking,
    linkTrackVars: commons.setLinkTrackVars([
      ...getGlobalEvars(),
      ...Object.keys(commonLooksTracking),
      "eVar106",
    ]),
    linkTrackEvents: setLinkTrackEvents({
      trackingName: TRACKING_NAME,
    }),
  });
};
