import { forwardRef } from "react";
import styles from "./SocialShareBar.scss";
import SocialShareLinks from "./SocialShareLinks";

const SocialShareBar = forwardRef((_, ref) => {
  return (
    <div ref={ref} className={styles.socialShareBarWrapper}>
      <SocialShareLinks />
    </div>
  );
});

SocialShareBar.displayName = "SocialShareBar";

export default SocialShareBar;
