import { useState, useRef } from "react";
import useInteractOutside from "../../hooks/useInteractOutside";
import styles from "./SocialShare.scss";
import SocialShareBar from "../socialShareBar/SocialShareBar";
import SocialShareBox from "../socialShareBar/SocialShareBox";
import { fireSocialShareIntent } from "../../analytics/pageInteraction";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { logError, ERROR_TYPE } from "../../utils/newRelicLogger";
import getWindow from "../../utils/window";
import { useViewport } from "../../context/viewport/ViewportContext";
import { SMALL_VIEWPORT } from "../../constants";
import classnamesBind from "classnames/bind";

const classnames = classnamesBind.bind(styles);

const SocialShare = () => {
  const [showSocialShareBar, setShowSocialShareBar] = useState(false);
  const [hasFiredSocialShareIntent, setHasFiredSocialShareIntent] =
    useState(false);
  const focusOnClose = useRef();
  const socialShareBarRef = useRef();
  const socialShareButton = useRef();
  const socialShareIcon = useRef();
  const { viewportCategory } = useViewport();
  const {
    getTranslation,
    product: { name },
    canonicalUrl,
  } = useRuntimeConstants();

  const closeSocialShare = () => {
    setShowSocialShareBar(false);
    focusOnClose.current?.focus();
  };

  useInteractOutside(socialShareBarRef, ({ target }) => {
    if (
      !socialShareButton.current?.contains(target) ||
      !socialShareIcon.current?.contains(target)
    ) {
      closeSocialShare();
    }
  });

  const triggerAnalyticIntent = () => {
    if (!hasFiredSocialShareIntent) {
      fireSocialShareIntent();
      setHasFiredSocialShareIntent(true);
    }
  };

  const openShareBar = () => {
    focusOnClose.current = getWindow().document.activeElement;
    setShowSocialShareBar(true);
  };

  const openNativeShare = async () => {
    if (getWindow().navigator.share) {
      try {
        await getWindow().navigator.share({
          title: name,
          text: name,
          url: `${canonicalUrl}#ctaref-product_share_native`,
        });
        return true;
      } catch (error) {
        if (error instanceof DOMException && error.name === "AbortError") {
          return true;
        }
        logError(ERROR_TYPE.UserActionFailed, {
          message: error.message,
          errorName: error.name,
        });
      }
    }

    return false;
  };

  const onShareIconClick = async () => {
    if (showSocialShareBar) {
      closeSocialShare();
      return;
    }

    if (!(await openNativeShare())) {
      openShareBar();
    }

    triggerAnalyticIntent();
  };

  return (
    <>
      <div className={styles.socialShareWrapper}>
        <button
          id="social-share-button"
          onClick={onShareIconClick}
          aria-label={getTranslation("pdp_cta_social_share")}
          ref={socialShareButton}
        >
          <span
            ref={socialShareIcon}
            className={classnames(styles.icon, "product-share-icon")}
          />
        </button>
      </div>
      {showSocialShareBar ? (
        viewportCategory === SMALL_VIEWPORT ? (
          <SocialShareBar ref={socialShareBarRef} />
        ) : (
          <SocialShareBox ref={socialShareBarRef} />
        )
      ) : null}
    </>
  );
};

export default SocialShare;
