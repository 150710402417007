import { useEffect } from "react";
import PropTypes from "prop-types";
import { PROP_65_URL } from "../../constants";
import classnamesBind from "classnames/bind";
import styles from "./Prop65FullWarning.scss";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useLiveRegion } from "../../context/liveRegion/LiveRegionContext";
import { setProp65Tracking } from "../../analytics/postPageLoad/prop65";

const classnames = classnamesBind.bind(styles);

function getMessageString(translation, { linkStyle }) {
  return translation("prop65_pdp_message_text", {
    warning: `<span class="${styles.warningText}">${translation(
      "prop65_message_title"
    )}</span>`,
    link: `<a class="${classnames(
      styles.link,
      linkStyle
    )}" target="_blank" rel="noopener" href="https://${PROP_65_URL}">${PROP_65_URL}</a>`,
  });
}

export default function Prop65FullWarning({
  linkStyle,
  contentStyle,
  containerStyle,
  subContainerStyle,
  titleStyle,
}) {
  const { updateLiveRegion } = useLiveRegion();
  const { getTranslation } = useRuntimeConstants();

  useEffect(() => {
    updateLiveRegion(
      getTranslation("prop65_pdp_message_text", {
        warning: getTranslation("prop65_message_title"),
        link: PROP_65_URL,
      })
    );
  }, []);

  setProp65Tracking();

  return (
    <section
      className={classnames(styles.container, containerStyle)}
      data-testid="prop65message"
      aria-labelledby="prop65title"
    >
      <div
        className={classnames(styles.subContainer, subContainerStyle)}
        data-testid="prop65_content"
      >
        <h4 className={classnames(styles.title, titleStyle)} id="prop65title">
          {getTranslation("prop65_pdp_message_title")}
        </h4>
        <p className={classnames(styles.content, contentStyle)}>
          <span
            className={classnames(styles.warningIcon, "product-warning-prop65")}
          />
          <span
            dangerouslySetInnerHTML={{
              __html: getMessageString(getTranslation, { linkStyle }),
            }}
          />
        </p>
      </div>
    </section>
  );
}

Prop65FullWarning.propTypes = {
  titleStyle: PropTypes.string,
  contentStyle: PropTypes.string,
  linkStyle: PropTypes.string,
  containerStyle: PropTypes.string,
  subContainerStyle: PropTypes.string,
};
