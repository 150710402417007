import { useEffect, useState } from "react";
import { getFeature } from "../../utils/features";
import getBuyTheLook from "../../api/buyTheLookApi";

const useBuyTheLookProducts = ({
  showUpSell,
  areFeaturesReady,
  isMixAndMatch,
  shouldShowLooksSection,
}) => {
  const [buyTheLookProducts, setBuyTheLookProducts] = useState([]);
  const [shouldDisplayBuyTheLook, setShouldDisplayBuyTheLook] = useState(false);

  useEffect(() => {
    if (showUpSell && areFeaturesReady) {
      const features = {
        "pdp-btl-from-search": getFeature("pdp-btl-from-search").isEnabled(),
      };

      setShouldDisplayBuyTheLook(features["pdp-btl-from-search"]);
    }
  }, [showUpSell, areFeaturesReady]);

  useEffect(() => {
    if (
      !isMixAndMatch &&
      shouldDisplayBuyTheLook &&
      shouldShowLooksSection === false
    ) {
      getBuyTheLook().then(setBuyTheLookProducts);
    }
  }, [shouldDisplayBuyTheLook, isMixAndMatch, shouldShowLooksSection]);

  return buyTheLookProducts;
};

export default useBuyTheLookProducts;
