import getWindow from "./window";
import getRuntimeConstants from "../runtimeConstants";
import { logError, ERROR_TYPE } from "./newRelicLogger";

const setBreadcrumbsSafely = (breadcrumbs) => {
  try {
    getWindow().asos.siteChrome.setBreadcrumbs(breadcrumbs);
  } catch (error) {
    logError(ERROR_TYPE.NonCriticalAppError, {
      message: error.message,
      action: "setBreadcrumbsSafely",
    });
  }
};

const encodeSearchQuery = ({ searchQuery }) =>
  getWindow().encodeURIComponent(searchQuery).replaceAll("%20", "+");

const setSearchBreadcrumbsData = ({
  getTranslation,
  breadcrumbsData,
  siteDomain,
  searchQuery,
}) => {
  const home = breadcrumbsData[0];
  const name = breadcrumbsData[breadcrumbsData.length - 1];
  const displayedBreadcrumbs = [
    home,
    {
      Url: `${siteDomain}/search/?q=${encodeSearchQuery({ searchQuery })}`,
      Label: getTranslation("pdp_breadcrumbs_search_results_for", {
        searchQuery,
      }),
    },
    name,
  ];
  return setBreadcrumbsSafely(displayedBreadcrumbs);
};

export const updateDisplayedBreadcrumbs = ({
  breadcrumbsData,
  siteDomain,
  getTranslation,
}) => {
  const { referrerData } = getRuntimeConstants();
  const searchQuery = referrerData?.searchQuery;

  if (searchQuery) {
    setSearchBreadcrumbsData({
      breadcrumbsData,
      siteDomain,
      getTranslation,
      searchQuery,
    });
  } else if (breadcrumbsData?.length) {
    setBreadcrumbsSafely(breadcrumbsData);
  }
};
