import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import getImageSource, {
  getImageSourceSet,
} from "../../../common/imagePresets/imagePresets";
import styles from "./Image.scss";

const classnames = classnamesBind.bind(styles);

export default function Image({
  additionalClasses,
  loadingType,
  url,
  width,
  type,
  alternateText,
  ariaHidden,
}) {
  return (
    <img
      className={classnames(styles.image, additionalClasses)}
      src={getImageSource(url, type, width)}
      srcSet={getImageSourceSet(url, type)}
      sizes={`${width}px`}
      alt={alternateText}
      loading={loadingType}
      aria-hidden={ariaHidden}
    />
  );
}

Image.propTypes = {
  url: PropTypes.string.isRequired,
  alternateText: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["hero", "carousel", "thumbnail", "collage"])
    .isRequired,
  additionalClasses: PropTypes.string,
  loadingType: PropTypes.string,
  width: PropTypes.number.isRequired,
  ariaHidden: PropTypes.bool,
};
