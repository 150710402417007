import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import useEventBus from "../../hooks/useEventBus";
import styles from "./AppNotificationBar.scss";
import useElementWhenReady from "../../hooks/useElementWhenReady";
import { NotificationBar } from "@asosteam/asos-web-component-library-notification-bar";
import getWindow from "../../utils/window";
import useInteractOutside from "../../hooks/useInteractOutside";
import { NOTIFICATION_DRAWER_ID } from "../../constants";

const renderBarInPortal = (Component, portalElement) => {
  return portalElement && ReactDOM.createPortal(<Component />, portalElement);
};
const isClick = (event) => event.type === "click";
const isEnterKey = (event) => event.code === "Enter" || event.keyCode === 13;
const isTab = (event) => event.code === "Tab" || event.keyCode === 9;

const refocus = (refocusId) => {
  if (refocusId) {
    const refocusElement = getWindow().document.getElementById(refocusId);
    if (refocusElement) {
      refocusElement.focus();
    }
  }
};

export default function AppNotificationBar() {
  const [notificationBarData, setNotificationBarData] = useState(null);
  const [open, setOpen] = useState(false);
  const [refocusId, setRefocusId] = useState(null);
  const notificationDrawer = useRef(null);
  const portalElement = useElementWhenReady("body");

  const barCloseHandler = () => {
    refocus(refocusId);
    setOpen(false);
  };

  const barOpenHandler = ({ copy, type, refocusId }) => {
    setNotificationBarData({
      type,
      copy,
    });
    setRefocusId(refocusId);
    setOpen(true);
  };

  useInteractOutside(notificationDrawer, barCloseHandler, [refocusId]);
  useEventBus("notificationBar", barOpenHandler, []);
  useEffect(() => {
    if (notificationDrawer.current && open) {
      const button = notificationDrawer.current.getElementsByTagName("button");
      button[0] && button[0].focus();
    }
  }, [open]);

  return renderBarInPortal(
    () => (
      <div
        id={NOTIFICATION_DRAWER_ID}
        data-testid={NOTIFICATION_DRAWER_ID}
        className={styles.notificationDrawer}
        ref={notificationDrawer}
      >
        {notificationBarData && open && (
          <NotificationBar
            type={notificationBarData.type}
            onClose={(event) => {
              if (isEnterKey(event) || isClick(event) || isTab(event)) {
                event.preventDefault();
                if (isTab(event)) return;
                barCloseHandler();
              }
            }}
          >
            {notificationBarData.copy}
          </NotificationBar>
        )}
      </div>
    ),
    portalElement
  );
}
