import { useCallback, useEffect, useRef } from "react";
import MightLike, { RECOMMENDATION_TYPES } from "../mightLike/MightLike";
import useRecommendations from "../mightLike/useRecommendations";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { PAGE_AREAS } from "../../constants";
import getDefaultCarouselSettings from "../../utils/getDefaultCarouselSettings";
import {
  Carousel,
  CarouselMode,
} from "@asosteam/asos-web-component-library-carousel";
import useGrid from "../grid/useGrid";
import Grid from "../grid/Grid";
import { buildProductTileAriaLabel } from "../productTile/buildProductTileAriaLabel";
import PriceThenTitle from "../productTile/PriceThenTitle";
import TitleThenPrice from "../productTile/TitleThenPrice";
import ProductTile from "../productTile/ProductTile";
import useDiscountPercentage from "../../hooks/useDiscountPercentage";
import { useViewport } from "../../context/viewport/ViewportContext";
import { useIsInStockContext } from "../../context/isInStock/IsInStockContext";
import getRecommendationsTitleAriaLabel from "../mightLike/getRecommendationsTitleAriaLabel";
import {
  setIntersectionObserver,
  unsetIntersectionObserver,
} from "../../utils/setIntersectionObserver";
import { fireMightLikeVisibleAnalytics } from "../../analytics/pageInteraction";

const INTERSECTION_OPTIONS = {
  rootMargin: "0px 0px -350px 0px",
  threshold: 0,
};

function getProductTiles({
  mightLikeProducts,
  gridEnabled,
  showDiscountPercentage,
  getTranslation,
}) {
  return mightLikeProducts.map(
    (
      {
        url,
        productTitle,
        brandName,
        priceData,
        colourWayId,
        productId,
        imageUrl,
      },
      index
    ) => (
      <ProductTile
        key={url}
        url={url}
        title={productTitle}
        colourWayId={colourWayId}
        productId={productId}
        imageUrl={imageUrl}
        index={index}
        pageArea={PAGE_AREAS.MIGHT_LIKE}
        shouldShowTitle={true}
        productTileAriaLabel={buildProductTileAriaLabel({
          getTranslation,
          title: gridEnabled ? brandName : productTitle,
          priceData,
          showDiscountPercentage,
        })}
      >
        {gridEnabled ? (
          <PriceThenTitle priceData={priceData} title={brandName} />
        ) : (
          <TitleThenPrice priceData={priceData} title={productTitle} />
        )}
      </ProductTile>
    )
  );
}

function handleFireMightLikeVisibleAnalytics(intersectionData) {
  if (intersectionData.isIntersecting) {
    fireMightLikeVisibleAnalytics();
  }
}

function shouldNotRender({ gridEnabled, mightLikeProducts }) {
  return gridEnabled === null || !mightLikeProducts?.length;
}

export default function YouMightAlsoLike() {
  const { product, getTranslation } = useRuntimeConstants();
  const { mightLikeProducts } = useRecommendations({
    productId: product?.id,
  });
  const { isInStock } = useIsInStockContext();
  const { gridEnabled } = useGrid({ isInStock });
  const { showDiscountPercentage } = useDiscountPercentage();
  const { viewportCategory } = useViewport();
  const observer = useRef(null);
  const element = useRef(null);

  const trackingRef = useCallback((node) => {
    if (node) {
      element.current = node;
      observer.current = setIntersectionObserver({
        element: element.current,
        options: INTERSECTION_OPTIONS,
        handleIntersectionData: handleFireMightLikeVisibleAnalytics,
        observeIntersectionOnce: true,
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      unsetIntersectionObserver(element.current, observer.current);
    };
  }, []);

  if (shouldNotRender({ gridEnabled, mightLikeProducts })) {
    return null;
  }

  return (
    <MightLike
      id={"mightLike"}
      title={
        isInStock
          ? getTranslation("pdp_carousel_recommendations_title")
          : getTranslation("pdp_carousel_recommendations_out_of_stock")
      }
      titleAriaLabel={getRecommendationsTitleAriaLabel({
        getTranslation,
        gridEnabled,
        isInStock,
      })}
      trackingRef={trackingRef}
      type={
        gridEnabled ? RECOMMENDATION_TYPES.GRID : RECOMMENDATION_TYPES.CAROUSEL
      }
    >
      {gridEnabled ? (
        <Grid ariaDescribedby="mightLikeTitle" gridTestId="mightLikeGrid">
          {getProductTiles({
            mightLikeProducts,
            gridEnabled,
            showDiscountPercentage,
            getTranslation,
          })}
        </Grid>
      ) : (
        <Carousel
          {...getDefaultCarouselSettings({
            getTranslation,
            viewportCategory,
            CarouselMode,
          })}
          ariaDescribedby="mightLikeTitle"
          carouselTestId="mightLikeCarousel"
        >
          {getProductTiles({
            mightLikeProducts,
            gridEnabled,
            showDiscountPercentage,
            getTranslation,
          })}
        </Carousel>
      )}
    </MightLike>
  );
}
