import { forwardRef } from "react";
import Button from "../button/Button";
import styles from "./GalleryButton.scss";
import classnamesBind from "classnames/bind";
import PropTypes from "prop-types";
import { BUTTON_TYPES } from "../button/ButtonTypes";
import { TEXT_SIZES } from "../../../constants";

const classnames = classnamesBind.bind(styles);

const GalleryButton = forwardRef(
  ({ iconClass, ariaLabel, buttonText, handleClick, dataTestId }, ref) => {
    return (
      <Button
        handleClick={handleClick}
        ariaLabel={ariaLabel}
        size={TEXT_SIZES.london3}
        type={BUTTON_TYPES.naked}
        dataTestId={dataTestId}
        buttonRef={ref}
        additionalClasses={styles.galleryButton}
      >
        <>
          {iconClass && (
            <span
              className={classnames(styles.icon, iconClass)}
              data-testid="galleryButtonIcon"
            ></span>
          )}
          <span className={styles.text}>{buttonText}</span>
        </>
      </Button>
    );
  }
);

GalleryButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  iconClass: PropTypes.string,
  dataTestId: PropTypes.string,
};

GalleryButton.displayName = "GalleryButton";

export default GalleryButton;
