import { useState, useEffect } from "react";
import { MODAL_TYPE } from "../../constants";
import Modal from "../modal/Modal";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import useEventBus from "../../hooks/useEventBus";
import { useLooksProducts } from "../../context/looks/LooksProductsContext";
import LooksCarousel from "./LooksCarousel";
import LooksProduct from "./LooksProduct";
import { ProductContextProvider } from "../../context/product/ProductContext";
import { VariantContextProvider } from "../../context/variant/VariantContext";
import { useSelectedLooksProduct } from "../../context/looks/SelectedLooksProductContext";
import { fireLooksModalOpen } from "../../analytics/looks/looksModal";
import {
  resetLooksTrackedProducts,
  fireLooksProductViewTracking,
} from "../../analytics/looks/looksProduct";
import { getPreselectedProduct } from "../../utils/product";
import getWindow from "../../utils/window";
import { usePreviousBackInStock } from "../../context/backInStock/BackInStockContext";
import LooksSimilarItems from "./looksSimilarItems";
import { LOOKS_OPEN_MODAL_EVENT } from "./constants";

export default function LooksModal() {
  const [modalId] = useState(
    `${MODAL_TYPE.SIDE}${Math.floor(Math.random() * 10000)}`
  );

  const [open, setOpen] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [focusOnCloseElement, setFocusOnCloseElement] = useState(null);

  const { getTranslation } = useRuntimeConstants();
  const { looksProducts } = useLooksProducts();
  const { selectedLooksProduct } = useSelectedLooksProduct();
  const { previousBackInStockProductId } = usePreviousBackInStock();

  useEventBus(
    LOOKS_OPEN_MODAL_EVENT,
    ({ focusOnCloseElement, position }) => {
      setOpen(true);
      setContentLoaded(true);
      setFocusOnCloseElement(focusOnCloseElement);
      fireLooksModalOpen({ products: looksProducts, position });
      resetLooksTrackedProducts();
    },
    [looksProducts]
  );

  useEffect(() => {
    const preselectedProduct = getPreselectedProduct(
      looksProducts,
      previousBackInStockProductId
    );
    if (preselectedProduct) {
      setOpen(true);
      setContentLoaded(true);
      setFocusOnCloseElement(
        getWindow().document.getElementsByTagName("main")[0]
      );
    }
  }, [focusOnCloseElement, looksProducts, previousBackInStockProductId]);

  useEffect(() => {
    if (open) {
      fireLooksProductViewTracking({
        looksProducts,
        selectedLooksProduct,
        isMobile: true,
      });
    }
  }, [open, looksProducts, selectedLooksProduct]);

  if (!looksProducts?.length) {
    return null;
  }

  return (
    <Modal
      title={getTranslation("pdp_associated_buy_the_look_title")}
      subtitle={getTranslation("buy_the_look_items_count", {
        smart_count: looksProducts?.length,
        count: looksProducts?.length,
      })}
      modalId={modalId}
      modalType={MODAL_TYPE.SIDE}
      closeModal={() => setOpen(false)}
      contentLoaded={contentLoaded}
      open={open}
      focusOnClose={focusOnCloseElement}
    >
      <LooksCarousel includeHeroProduct={true} />
      {looksProducts.map((looksProduct) =>
        looksProduct.id === selectedLooksProduct?.id ? (
          <ProductContextProvider
            products={looksProducts}
            productId={looksProduct.id}
            key={looksProduct.id}
          >
            <VariantContextProvider>
              <LooksProduct />
            </VariantContextProvider>
            <LooksSimilarItems />
          </ProductContextProvider>
        ) : null
      )}
    </Modal>
  );
}
