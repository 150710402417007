import PropTypes from "prop-types";
import styles from "./Recommended.scss";
import { getIntlNumberFormat } from "../../utils/format";
import { composeTranslation } from "../../utils/composeTranslation";
import classnamesBind from "classnames/bind";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

const classnames = classnamesBind.bind(styles);

export default function Recommended({
  percentageRecommended,
  language,
  additionalClasses,
}) {
  const { getTranslation } = useRuntimeConstants();
  if (percentageRecommended === null) {
    return null;
  }

  return (
    <p className={classnames(styles.recommended, additionalClasses)}>
      {composeTranslation(
        getTranslation("pdp_ratings_reviews_recommendation_percentage"),
        {
          percentageRecommeded: getIntlNumberFormat({
            value: percentageRecommended / 100,
            language,
            options: { style: "percent" },
          }),
        }
      )}
    </p>
  );
}

Recommended.propTypes = {
  percentageRecommended: PropTypes.number,
  language: PropTypes.string.isRequired,
  additionalClasses: PropTypes.string,
};
