import { getPrimaryImageName } from "../../../utils/product";
import { useLooksProducts } from "../../../context/looks/LooksProductsContext";
import { useRuntimeConstants } from "../../../context/runtimeConstants/RuntimeConstantsContext";
import getConfig from "../../../config";

const LOOKS_IMAGE_TEMPLATE_PATH_UPTO_THREE =
  "products/looks/looks-template-upto-3";
const LOOKS_IMAGE_TEMPLATE_PATH_OVER_THREE =
  "products/looks/looks-template-over-3";

function buildLooksImageUrl({
  looksProducts,
  product,
  numberOfProducts,
  path,
  numberOfDisplayedImages,
}) {
  const { imageServer } = getConfig();
  const looksImagePath = new URL(`${imageServer}/${path}/?`);
  const params = [];

  params.push(`$look_1=${getPrimaryImageName(product.images)}`);
  looksProducts
    .filter((product) => !product.isHero)
    .forEach((product, index) =>
      params.push(`$prod_${index + 1}=${getPrimaryImageName(product.images)}`)
    );

  if (numberOfProducts > numberOfDisplayedImages) {
    params.push(`$counter_text=${numberOfProducts - numberOfDisplayedImages}`);
    params.push("$dark_layer_opacity=80");
    params.push("$counter_opacity=100");
  } else {
    params.push("$dark_layer_opacity=0");
    params.push("$counter_opacity=0");
  }

  looksImagePath.search = params.join("&");
  return looksImagePath.toString();
}

export default function useLooksImageUrl() {
  const { looksProducts } = useLooksProducts();
  const { product } = useRuntimeConstants();
  const numberOfProducts = looksProducts.length;

  if (numberOfProducts > 4) {
    return buildLooksImageUrl({
      looksProducts,
      product,
      numberOfProducts,
      path: LOOKS_IMAGE_TEMPLATE_PATH_OVER_THREE,
      numberOfDisplayedImages: 5,
    });
  }
  return buildLooksImageUrl({
    looksProducts,
    product,
    numberOfProducts,
    path: LOOKS_IMAGE_TEMPLATE_PATH_UPTO_THREE,
    numberOfDisplayedImages: 2,
  });
}
