import PropTypes from "prop-types";
import styles from "./ProductCard.scss";
import Image from "../image/Image";
import { getPrimaryImage } from "../../utils/product";
import SaveForLaterButton from "../buttons/saveForLaterButton/SaveForLaterButton";
import Spacing, { SPACING } from "../spacing/Spacing";
import ShowOnProductStockStatus from "../conditional/ShowOnProductStockStatus";
import OutOfStockLabel from "../outOfStockLabels/OutOfStockLabel";
import { SECONDARY_LABEL } from "../outOfStockLabels/labelTypes";
import { PrimaryPrice } from "../priceComponents";
import { COMPLEMENTARY_PRIMARY_PRICE } from "../priceComponents/priceTypes";
import SoldShippedHeader from "../deliveryAndReturns/soldShippedHeader/SoldShippedHeader";
import { TEXT_SIZES } from "../../constants";
import ProductColourForProductCard from "../productColour/ProductColourForProductCard";
import ProductSize from "../productSize/ProductSize";
import VariantSelectionContainer from "../../containers/variantSelection/VariantSelectionContainer";
import classnamesBind from "classnames/bind";

const classnames = classnamesBind.bind(styles);

export default function ProductCard({
  product,
  productLink,
  handleSaveClick,
  variantIdentifier,
  fireAddToBagAnalytics,
  fireAddToBagErrorAnalytics,
  fireAddToBagMarketing,
  fireNotifyMeClickAnalytics,
  variantSelectorId,
  scope,
  imageWidth = 163,
  shouldShowProductControls = true,
  shouldRetainSmallImageSize,
}) {
  return (
    <section className={styles.container} data-testid="productCard">
      <div className={styles.imageContainer}>
        <a
          data-testid="productCardImageLink"
          href={productLink}
          className={classnames(styles.imageLink, {
            smallImageOnly: shouldRetainSmallImageSize,
          })}
        >
          <Image
            url={getPrimaryImage(product.images).url}
            alternateText={product.name}
            type={"card"}
            width={imageWidth}
          />
        </a>
        {shouldShowProductControls && (
          <SaveForLaterButton
            handleClick={handleSaveClick}
            productTitle={product.name}
            variantIdentifier={variantIdentifier}
            saveForLaterClass="buyTheLookSaveForLater"
          />
        )}
      </div>
      <div className={styles.aside}>
        <Spacing size={SPACING.EIGHT} excludeFirst={true}>
          <ShowOnProductStockStatus productStockStatus={false}>
            <OutOfStockLabel labelStyle={SECONDARY_LABEL} />
          </ShowOnProductStockStatus>
          <a
            data-testid="productCardTitleLink"
            href={productLink}
            className={styles.link}
          >
            <h2 className={styles.title}>{product.name}</h2>
          </a>
          <PrimaryPrice
            priceData={product.price}
            testId="productCardPrice"
            priceType={COMPLEMENTARY_PRIMARY_PRICE}
          />
          <SoldShippedHeader size={TEXT_SIZES.leavesden3} />
          <ProductColourForProductCard />
          <ProductSize size={TEXT_SIZES.leavesden3} />
          {!product?.isDiscontinued ? (
            <Spacing size={SPACING.TWELVE}>
              {shouldShowProductControls && (
                <VariantSelectionContainer
                  isInStock={product?.isInStock}
                  shouldShowSave={false}
                  variantSelectorId={variantSelectorId}
                  fireNotifyMeClickAnalytics={fireNotifyMeClickAnalytics}
                  fireAddToBagAnalytics={fireAddToBagAnalytics}
                  fireAddToBagErrorAnalytics={fireAddToBagErrorAnalytics}
                  fireAddToBagMarketing={fireAddToBagMarketing}
                  scope={scope}
                  selectedProductId={product.id}
                  isLooksProduct={true}
                />
              )}
            </Spacing>
          ) : null}
        </Spacing>
      </div>
    </section>
  );
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    isDiscontinued: PropTypes.boolean.isRequired,
    isInStock: PropTypes.bool.isRequired,
    shouldShowSave: PropTypes.bool.isRequired,
    isLooksProduct: PropTypes.bool,
    images: PropTypes.arrayOf({
      isPrimary: PropTypes.bool.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    price: PropTypes.shape({
      current: PropTypes.shape({
        text: PropTypes.string.isRequired,
        value: PropTypes.number,
      }).isRequired,
      previous: PropTypes.shape({
        text: PropTypes.string.isRequired,
        value: PropTypes.number,
      }),
      rrp: PropTypes.shape({
        text: PropTypes.string.isRequired,
        value: PropTypes.number,
      }),
      isMarkedDown: PropTypes.bool,
      isOutletPrice: PropTypes.bool,
      startDateTime: PropTypes.string,
    }).isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  productLink: PropTypes.string.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  variantIdentifier: PropTypes.string.isRequired,
  fireAddToBagAnalytics: PropTypes.func,
  fireAddToBagErrorAnalytics: PropTypes.func,
  fireAddToBagMarketing: PropTypes.func,
  fireNotifyMeClickAnalytics: PropTypes.func,
  variantSelectorId: PropTypes.string.isRequired,
  scope: PropTypes.string,
  imageWidth: PropTypes.number.isRequired,
  shouldShowProductControls: PropTypes.bool,
  shouldRetainSmallImageSize: PropTypes.bool,
};
