import PropTypes from "prop-types";
import {
  Carousel,
  CarouselMode,
} from "@asosteam/asos-web-component-library-carousel";
import ProductTile from "../productTile/ProductTile";
import styles from "./PeopleAlsoBought.scss";
import RankingDisclaimerMessage from "../rankingDisclaimerMessage/RankingDisclaimerMessage";
import { PAGE_AREAS } from "../../constants";
import getDefaultCarouselSettings from "../../utils/getDefaultCarouselSettings";
import { useViewport } from "../../context/viewport/ViewportContext";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { buildProductTileAriaLabel } from "../productTile/buildProductTileAriaLabel";
import TitleThenPrice from "../productTile/TitleThenPrice";

export default function PeopleAlsoBought({
  sdks,
  products,
  showDiscountPercentage,
}) {
  const { viewportCategory } = useViewport();
  const { getTranslation } = useRuntimeConstants();

  return (
    <div id="peopleAlsoBought" className={styles.fullwidth_container}>
      <section
        className={styles.container}
        aria-label={getTranslation("complementary_items_carousel_label")}
      >
        <header className={styles.header}>
          <h2 id="peopleAlsoBoughtTitle" className={styles.title}>
            {getTranslation("complementary_items_title")}
          </h2>
          <RankingDisclaimerMessage />
        </header>
        <Carousel
          {...getDefaultCarouselSettings({
            getTranslation,
            viewportCategory,
            CarouselMode,
          })}
          ariaDescribedby="peopleAlsoBoughtTitle"
        >
          {products.map(
            (
              {
                productId,
                url,
                imageUrl,
                productTitle,
                colourWayId,
                priceData,
              },
              index
            ) => (
              <ProductTile
                productId={productId}
                colourWayId={colourWayId}
                url={url}
                imageUrl={imageUrl}
                title={productTitle}
                index={index}
                sdks={sdks}
                pageArea={PAGE_AREAS.PEOPLE_ALSO_BOUGHT}
                shouldShowTitle={true}
                saveForLaterClass={styles.saveForLater}
                priceData={priceData}
                productTileAriaLabel={buildProductTileAriaLabel({
                  getTranslation,
                  title: productTitle,
                  priceData,
                  showDiscountPercentage,
                })}
                key={url}
              >
                <TitleThenPrice priceData={priceData} title={productTitle} />
              </ProductTile>
            )
          )}
        </Carousel>
      </section>
    </div>
  );
}

PeopleAlsoBought.propTypes = {
  sdks: PropTypes.shape({ savedItems: PropTypes.object.isRequired }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      productTitle: PropTypes.string.isRequired,
      colourWayId: PropTypes.number.isRequired,
      priceData: PropTypes.shape({
        current: PropTypes.shape({
          text: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
  showDiscountPercentage: PropTypes.bool.isRequired,
};
