import getRequest from "../utils/requests";
import { formatUrl, queryObjectToString } from "../utils/url";
import getRuntimeConstants from "../runtimeConstants";
import { logError, ERROR_TYPE } from "../utils/newRelicLogger";

function formatProductLink(url, indexZeroBased) {
  const separator = url.includes("#") ? "&" : "#";
  const queryString = `${separator}CTAref-Complete+the+Look+Carousel_${
    indexZeroBased + 1
  }&featureref1-complete+the+look`;

  return `${url}${queryString}`;
}

export default async function getBuyTheLook() {
  const {
    webContext: {
      keyStoreDataversion,
      browseCurrency,
      browseLanguage,
      storeId,
      browseCountry,
    },
    product: { id },
    buyTheLookFromSearchUrl,
    buyTheLookFromSearchTimeout,
    siteDomain,
  } = getRuntimeConstants();

  const url = `${buyTheLookFromSearchUrl}${id}`;
  const params = {
    keyStoreDataversion,
    store: storeId,
    currency: browseCurrency,
    lang: browseLanguage,
    country: browseCountry,
  };

  return getRequest()
    .get({
      url,
      timeout: buyTheLookFromSearchTimeout,
      params,
      additionalLoggingParams: {
        requestType: "BUY_THE_LOOK_API",
      },
    })
    .then((response) => {
      if ([202, 204].includes(response.status)) {
        return [];
      }
      return response.data.products.map(
        ({ id, url, imageUrl, name, colourWayId, price }, index) => ({
          productId: id,
          url: formatProductLink(`${siteDomain}/${url}`, index),
          imageUrl: formatUrl(imageUrl),
          productTitle: name,
          colourWayId,
          priceData: price,
        })
      );
    })
    .catch((error) => {
      logError(ERROR_TYPE.AjaxError, {
        apiName: "Search",
        caller: "BuyTheLook",
        requestUrl: `${url}?${queryObjectToString(params)}`,
        message: error.message,
        status: error.response?.status,
        response: error.response?.data,
        requestHeaders: error.config?.headers,
        responseHeaders: error.response?.headers,
      });
      return [];
    });
}
