import getWindow from "./window";
// For use of checking existence of elements outside React codebase

export default function whenElementExists(elementSelector, root, callbackFunc) {
  let elementNode;

  new Promise((resolve) => {
    elementNode = getWindow().document.querySelector(elementSelector);
    if (elementNode) {
      return resolve();
    }

    const mutationObserver = getWindow().MutationObserver;

    const observer = new mutationObserver(() => {
      elementNode = getWindow().document.querySelector(elementSelector);
      if (elementNode) {
        resolve();
        observer.disconnect();
      }
    });

    observer.observe(root, {
      childList: true,
      subtree: true,
    });
  }).then(() => {
    callbackFunc(elementNode);
  });
}
