import { useState, useEffect } from "react";
import { getFeature } from "../../utils/features";
import getRecommendations from "../../api/mightLikeApi";

const shouldDisplayPeopleAlsoBought = ({ showUpSell, isInStock }) =>
  showUpSell &&
  getFeature("pdp-people-also-bought", null, {
    isInStock,
  }).isEnabled();

const useRecommendationProducts = ({
  showUpSell,
  isInStock,
  isStockStateReady,
  areFeaturesReady,
  isMixAndMatch,
}) => {
  const [recommendationProducts, setRecommendationProducts] = useState([]);

  useEffect(() => {
    if (
      isStockStateReady &&
      areFeaturesReady &&
      !isMixAndMatch &&
      shouldDisplayPeopleAlsoBought({ showUpSell, isInStock })
    ) {
      getRecommendations({ isInStock, type: "complementary" }).then(
        (products) => {
          setRecommendationProducts(products);
        }
      );
    }
  }, [
    showUpSell,
    isInStock,
    isStockStateReady,
    areFeaturesReady,
    isMixAndMatch,
  ]);

  return recommendationProducts;
};

export default useRecommendationProducts;
