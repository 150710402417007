import { useState } from "react";
import ShippingRestrictionsModal from "../../components/shippingRestrictionsModal/ShippingRestrictionsModal";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import DeliveryAndReturns from "../../components/deliveryAndReturns/DeliveryAndReturns";
import { useIsInStockContext } from "../../context/isInStock/IsInStockContext";
import { useStockPrice } from "../../context/stockPrice/StockPriceContext";

export default function DeliveryAndReturnsContainer() {
  const { isMixAndMatch } = useRuntimeConstants();
  const { products } = useStockPrice();
  const { isInStock } = useIsInStockContext();
  const [
    shippingRestrictionsIncludedCountries,
    setShippingRestrictionsIncludedCountries,
  ] = useState([]);
  const [openShippingRestrictions, setOpenShippingRestrictions] =
    useState(false);
  const [
    shippingRestrictionsModalFocusOnClose,
    setShippingRestrictionsModalFocusOnClose,
  ] = useState(null);

  const renderDeliveryAndReturns = () => {
    if (products && isInStock === true) {
      if (isMixAndMatch && products.products) {
        return products.products.map(
          ({ variants, shippingRestrictions, id, isInStock }, index) =>
            isInStock && (
              <DeliveryAndReturns
                key={id}
                variants={variants}
                productId={id}
                shippingRestrictions={shippingRestrictions}
                index={index}
                toggleShippingRestrictions={toggleShippingRestrictions}
              />
            )
        );
      }

      return (
        <DeliveryAndReturns
          variants={products.variants}
          shippingRestrictions={products.shippingRestrictions}
          toggleShippingRestrictions={toggleShippingRestrictions}
          productId={products.id}
        />
      );
    }
  };

  const toggleShippingRestrictions = (
    openShippingRestrictions,
    shippingRestrictionsIncludedCountries,
    shippingRestrictionsModalFocusOnClose
  ) => {
    setOpenShippingRestrictions(openShippingRestrictions);

    setShippingRestrictionsIncludedCountries(
      shippingRestrictionsIncludedCountries ?? []
    );

    shippingRestrictionsModalFocusOnClose &&
      setShippingRestrictionsModalFocusOnClose(
        shippingRestrictionsModalFocusOnClose
      );
  };

  if (!isMixAndMatch) {
    return null;
  }

  return (
    <>
      {renderDeliveryAndReturns()}
      <ShippingRestrictionsModal
        includedCountries={shippingRestrictionsIncludedCountries}
        open={openShippingRestrictions}
        focusOnClose={shippingRestrictionsModalFocusOnClose}
        toggleShippingRestrictions={toggleShippingRestrictions}
      />
    </>
  );
}
