import { MAX_RECENTLY_VIEWED_PRODUCTS } from "../../constants";
import getConstants from "../../runtimeConstants";
import { getVariantFromUrl } from "../../utils/url";
import getWindow from "../../utils/window";

const STORAGE_KEY = "asos-pdp-recentlyViewed";

export async function savePageToLocalStorage() {
  const { hostAndPath, product, isMixAndMatch } = getConstants();
  const { id: productId, name: productTitle } = product;
  const imageUrl = product.images[0].url;
  let colourWayId;

  if (!isMixAndMatch) {
    const variantFromUrl = getVariantFromUrl(product);
    if (variantFromUrl) {
      colourWayId = variantFromUrl.colourWayId;
    } else {
      colourWayId = product.variants[0].colourWayId;
    }
  }

  setProduct({
    productId,
    url: `${hostAndPath}#ctaref-recently+viewed`,
    imageUrl,
    colourWayId,
    productTitle,
    isMixAndMatch,
  });
}

function getSavedObjectInStorage() {
  const recentlyViewedInLocalStorage =
    getWindow().localStorage.getItem(STORAGE_KEY);
  if (typeof recentlyViewedInLocalStorage === "string") {
    return JSON.parse(recentlyViewedInLocalStorage);
  }
  return { products: [] };
}

export function getProductsInStorage() {
  const { webContext } = getConstants();
  const { storeId } = webContext;

  if (getSavedObjectInStorage().savedStore !== storeId) {
    clearProducts();
    return [];
  }
  return getSavedObjectInStorage().products;
}

export function clearProducts() {
  getWindow().localStorage.removeItem(STORAGE_KEY);
}

export function removeProduct(removeProductId) {
  const { webContext } = getConstants();
  const { storeId } = webContext;
  const products = [...getProductsInStorage()];

  const pageIndex = products.findIndex(
    ({ productId }) => productId === removeProductId
  );

  if (pageIndex !== -1) {
    products.splice(pageIndex, 1);
  }

  saveProductToLocalStorage({
    savedStore: storeId,
    products,
  });
}

function setProduct(value) {
  const { webContext } = getConstants();
  const { storeId } = webContext;
  const { products } = getSavedObjectInStorage();
  const currentlySavedProducts = [...products];
  const pageIndex = currentlySavedProducts.findIndex(
    ({ productId }) => productId === value.productId
  );

  if (pageIndex !== -1) {
    currentlySavedProducts.splice(pageIndex, 1);
  }

  currentlySavedProducts.unshift(value);
  if (currentlySavedProducts.length > MAX_RECENTLY_VIEWED_PRODUCTS) {
    currentlySavedProducts.pop();
  }
  saveProductToLocalStorage({
    savedStore: storeId,
    products: currentlySavedProducts,
  });
}

function saveProductToLocalStorage(savedObject) {
  getWindow().localStorage.setItem(STORAGE_KEY, JSON.stringify(savedObject));
}
