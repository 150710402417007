export function composeTranslation(translationString, substitutions) {
  if (typeof substitutions === "object") {
    Object.keys(substitutions).forEach((key) => {
      translationString = translationString.replace(
        `%{${key}}`,
        substitutions[key]
      );
    });
  }
  if (translationString.match(/%{.*}/)) {
    throw new Error(`Missing substitution for ${translationString}`);
  }

  return translationString;
}
