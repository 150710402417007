import { getCtaRef } from "../analytics/common";
import { getFeature } from "./features";

export const trackCtaRefInOptimizely = async () => {
  let ctaRef = getCtaRef();
  if (ctaRef) {
    ctaRef = ctaRef && ctaRef.split("_")[0].toLowerCase();
    const matchedTracker = window.asos.pdp.config.featuresCtaTracking.find(
      (feature) => feature.matchCta.toLowerCase() === ctaRef
    )?.trackingKey;
    if (matchedTracker) {
      const { track } = getFeature(matchedTracker);
      await track(matchedTracker);
    }
  }
};
