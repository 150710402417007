import PropTypes from "prop-types";
import styles from "./Grid.scss";
import classnamesBind from "classnames/bind";
import { RECOMMENDATION_TYPES } from "../mightLike/MightLike";

const classnames = classnamesBind.bind(styles);

export default function Grid({ gridTestId, children, type }) {
  return (
    <section
      data-testid={gridTestId}
      className={classnames(styles.grid, styles[type])}
    >
      <ul className={styles.list}>
        {children.map((item, index) => (
          <li key={index} className={styles.listItem}>
            {item}
          </li>
        ))}
      </ul>
    </section>
  );
}

Grid.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  gridTestId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(RECOMMENDATION_TYPES)),
};
