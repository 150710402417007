import { useEffect } from "react";
import getWindow from "../utils/window";

export default function useTouchControl({
  startHandler,
  endHandler,
  moveHandler,
  cancelHandler,
}) {
  useEffect(() => {
    startHandler && getWindow().addEventListener("touchstart", startHandler);

    return () => {
      getWindow().removeEventListener("touchstart", startHandler);
    };
  }, [startHandler]);

  useEffect(() => {
    endHandler && getWindow().addEventListener("touchend", endHandler);

    return () => {
      getWindow().removeEventListener("touchend", endHandler);
    };
  }, [endHandler]);

  useEffect(() => {
    moveHandler && getWindow().addEventListener("touchmove", moveHandler);

    return () => {
      getWindow().removeEventListener("touchmove", moveHandler);
    };
  }, [moveHandler]);

  useEffect(() => {
    cancelHandler && getWindow().addEventListener("touchcancel", cancelHandler);

    return () => {
      getWindow().removeEventListener("touchcancel", cancelHandler);
    };
  }, [cancelHandler]);
}
