import getStores from "../api/storeApi";
import {
  CALIFORNIA_REGION_CODE,
  US_STORE,
  PROP_65_SESSION_KEY,
} from "../constants";
import getRuntimeConstants from "../runtimeConstants";
import getWindow from "./window";

async function shouldShowBasedOnStoreApi() {
  const stores = await getStores();
  let shouldShow = false;

  if (stores !== undefined) {
    shouldShow =
      !!stores &&
      stores.some(({ region }) => region === CALIFORNIA_REGION_CODE);
    getWindow().sessionStorage.setItem(PROP_65_SESSION_KEY, shouldShow);
  }

  return shouldShow;
}

export default async function shouldShowProp65({
  product: { hasVariantsWithProp65Risk },
  isInStock,
}) {
  const {
    webContext: { storeId },
  } = getRuntimeConstants();

  if (storeId !== US_STORE || !hasVariantsWithProp65Risk || !isInStock) {
    return false;
  }

  const shouldShowFromSessionStorage =
    getWindow().sessionStorage.getItem(PROP_65_SESSION_KEY);

  if (["true", "false"].includes(shouldShowFromSessionStorage)) {
    return shouldShowFromSessionStorage === "true";
  }

  return await shouldShowBasedOnStoreApi();
}
