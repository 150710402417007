import PropTypes from "prop-types";
import styles from "./Reviews.scss";
import Review from "../review/Review";
import StandardSpinner from "../spinner/standard/StandardSpinner";
import { getIntlNumberFormat } from "../../utils/format";
import getWindow from "../../utils/window";
import { SMALL_SPINNER } from "../../constants";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";

export default function Reviews({
  reviews,
  totalReviewCount,
  getReviewsCallback,
  viewMorePending,
  language,
  togglePhotoGallery,
}) {
  const { getTranslation } = useRuntimeConstants();
  function getReviewKey(index, review) {
    if (review.title) {
      return `${index}-${review.title.slice(0, 10)}`;
    }
    if (review.reviewText) {
      return `${index}-${review.reviewText.slice(0, 10)}`;
    }
    return index;
  }

  function getIntlNumber(value) {
    return getIntlNumberFormat({ language, value });
  }

  async function viewMore() {
    await getReviewsCallback();
    const reviewElement = getWindow().document.querySelectorAll(
      "[data-testid='reviewsModalReview']"
    )[reviews.length + 1];
    reviewElement && reviewElement.focus();
  }

  return (
    <>
      {reviews.map((review, index) => (
        <Review
          additionalClasses={styles.review}
          key={getReviewKey(index, review)}
          displayPhotos={true}
          togglePhotoGallery={togglePhotoGallery}
          {...review}
        />
      ))}
      <div className={styles.viewMoreContainer}>
        <p className={styles.reviewsCount} data-testid="reviewsCount">
          {getTranslation("rdp_ratings_reviews_progress_text", {
            viewedReviews: getIntlNumber(reviews.length),
            totalResults: getIntlNumber(totalReviewCount),
            smart_count: totalReviewCount,
          })}
        </p>
        {totalReviewCount > 10 && (
          <>
            <progress
              className={styles.reviewsProgress}
              value={reviews.length}
              max={totalReviewCount}
              aria-hidden={true}
            />
            {viewMorePending ? (
              <StandardSpinner
                additionalClasses={styles.spinner}
                size={SMALL_SPINNER}
              />
            ) : (
              reviews.length < totalReviewCount && (
                <button
                  className={styles.viewMore}
                  data-testid="reviewsViewMore"
                  onClick={viewMore}
                  id="viewMoreRatings"
                >
                  {getTranslation(
                    "rdp_ratings_reviews_distribution_view_more_cta"
                  )}
                </button>
              )
            )}
          </>
        )}
      </div>
    </>
  );
}

Reviews.propTypes = {
  totalReviewCount: PropTypes.number.isRequired,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      reviewText: PropTypes.string,
      rating: PropTypes.number.isRequired,
      submissionRecency: PropTypes.string.isRequired,
      syndicationSource: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      badgesOrder: PropTypes.array.isRequired,
    }).isRequired
  ),
  getReviewsCallback: PropTypes.func.isRequired,
  viewMorePending: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  togglePhotoGallery: PropTypes.func.isRequired,
};
