import useEventListener from "./useEventListener";
import { NOTIFICATION_DRAWER_ID } from "../constants";

const useInteractOutside = (wrapperRef, callback, dependencies = []) => {
  const handleMouseDown = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      wrapperRef.current.id !== NOTIFICATION_DRAWER_ID
    ) {
      callback(event);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 27 || event.key === "escape") {
      callback(event);
    }
  };

  useEventListener("keydown", handleKeyDown, dependencies);

  useEventListener("mousedown", handleMouseDown, dependencies);
};

export default useInteractOutside;
