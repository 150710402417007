import useAdverts from "../../hooks/useAdverts";
import { PLACEMENT, APP } from "@asosteam/asos-web-adverts-sdk";
import Advert from "@asosteam/asos-web-adverts-display";
import { useIsInStockContext } from "../../context/isInStock/IsInStockContext";
import { useStockPrice } from "../../context/stockPrice/StockPriceContext";
import { getFirstVariant, getPreselectedVariant } from "../../utils/product";
import styles from "./AdvertBanner.scss";
import { usePreviousBackInStock } from "../../context/backInStock/BackInStockContext";

const FALLBACK_PRICE = 1;

const AdvertBanner = () => {
  const { advertsSdkInstance } = useAdverts();
  const { isStockStateReady, isInStock } = useIsInStockContext();
  const { products } = useStockPrice();
  const { previousBackInStockVariantId } = usePreviousBackInStock();

  const getSelectedOrOnlyVariantId = () => {
    const selectedVariant = getPreselectedVariant(
      products,
      previousBackInStockVariantId
    );

    if (selectedVariant) {
      return selectedVariant.variantId;
    }

    const onlyVariant = getFirstVariant(products);

    if (onlyVariant) {
      return onlyVariant.variantId;
    }

    return undefined;
  };

  return isStockStateReady ? (
    <section className={styles.advertContainer}>
      <Advert
        sdkInstance={advertsSdkInstance}
        app={APP.PDP}
        placement={PLACEMENT.BOTTOM}
        requestSponsoredProducts={false}
        requestDisplayAdverts={true}
        parameters={{
          item: getSelectedOrOnlyVariantId(),
          "parent-item": products.id,
          price: FALLBACK_PRICE,
          "list-price": FALLBACK_PRICE,
          availability: Number(isInStock),
        }}
        rootMargin={"500px"}
      />
    </section>
  ) : null;
};

export default AdvertBanner;
